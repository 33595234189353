import { Typography } from "components"
import styled from "styled-components"
import { AppColor } from "theme/app-color"

interface Condition {
  description: string
  pointRequired: boolean
  withDrawAmountLimit: number
  withdrawTimesPerYear: number
}

interface ClaimConditionProps {
  conditions: Condition[]
}

const Container = styled.div`
  margin-top: 24px;
`

const List = styled.ul`
  margin-top: 8px;
  margin-left: -16px;
`

const ClaimConditions = (props: ClaimConditionProps) => {
  const { conditions } = props
  return (
    <Container>
      {conditions.length > 0 && (
        <Typography variant="h3" color={AppColor["Text/Primary Text"]}>
          เงื่อนไขการเบิก
        </Typography>
      )}
      <List>
        {conditions.map((condition) => {
          if (condition.description)
            return (
              <li key={`${condition.description}-6`}>
                <Typography variant="h4" color={AppColor["Text/Secondary Text"]}>
                  {condition.description}
                </Typography>
              </li>
            )
          return <></>
        })}
      </List>
    </Container>
  )
}

export default ClaimConditions
