import { compose, withHooks } from "enhancers"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import { Switch } from "formik-material-ui"
import PropTypes from "prop-types"

const SwitchInput = (props) => (
  <FormControlLabel
    control={<Switch {...props} type="checkbox" checked={props.field.value === true} />}
    label={props.label}
  />
)

const enhancer = compose(
  withHooks((props) => {
    return {
      key: props.forceFix ? `${props.field?.value}` : undefined,
      ...props,
    }
  }),
)

SwitchInput.propTypes = {
  field: PropTypes.any.isRequired,
  label: PropTypes.any.isRequired,
}

export default enhancer(SwitchInput)
