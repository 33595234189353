import React from "react"
import { compose, defaultProps, withHooks } from "enhancers"
import { Box, Typography, Avatar as MuiAvatar, Hidden, BrowseButton } from "components"
import { ReactComponent as UploadIcon } from "assets/icon/upload_icon.svg"
import PropTypes from "prop-types"

const UploadAvatar = (props) => (
  <Box display="flex" flexDirection="column" alignItems="center" width={327}>
    <MuiAvatar src={props.src} width={120} height={120} style={{ backgroundColor: "#376FD0" }}>
      ML
    </MuiAvatar>
    <BrowseButton startIcon={props.uploadButtonIcon} mt={2} onBrowse={props.chooseImage}>
      {props.uploadButtonLabel}
    </BrowseButton>
    <Hidden when={!props.remark}>
      <Typography variant="caption" mt={2}>
        {props.remark}
      </Typography>
    </Hidden>
  </Box>
)

const enhancer = compose(
  defaultProps({
    uploadButtonIcon: <UploadIcon />,
    uploadButtonLabel: "อัปโหลด",
    remark: "แนะนำให้ใช้รูป .jpg ขนาดอย่างน้อย 128 x 128px",
  }),
  withHooks((props, hooks) => {
    const { useState, useCallback } = hooks
    const { uploadButtonIcon, uploadButtonLabel, remark, defaultSrc } = props
    const { name } = props.field || {}
    const { setFieldValue, values } = props.form

    const [browsedSrc, setBrowsedSrc] = useState(null)

    const src = values ? values[name.replace(/File$/, "Url")] : null

    const chooseImage = useCallback(
      (image) => {
        if (image) {
          image.preview = URL.createObjectURL(image)
          setBrowsedSrc(image.preview)
          if (setFieldValue) {
            setFieldValue(name, image)
          }
        }
      },
      [setBrowsedSrc, setFieldValue, name],
    )

    const customSrc = browsedSrc || src || defaultSrc

    return {
      uploadButtonIcon,
      uploadButtonLabel,
      remark,
      chooseImage,
      src: customSrc,
    }
  }),
)

UploadAvatar.propTypes = {
  uploadButtonIcon: PropTypes.any.isRequired,
  uploadButtonLabel: PropTypes.any.isRequired,
  remark: PropTypes.any.isRequired,
  chooseImage: PropTypes.any.isRequired,
  src: PropTypes.any.isRequired,
}

export default enhancer(UploadAvatar)
