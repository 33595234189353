import { ApolloError } from "@apollo/client"
import { Card, CardContent } from "@material-ui/core"
import { Box, Button, Checkbox, Field, Grid, Icon, Modal, MuiIcon, Notification, Typography } from "components"
import { ClaimCard } from "components/advance/ClaimCard"
import { compose, withHooks, withStores } from "enhancers"
import { env } from "configs"
import { PageContent } from "layouts"
import { Link } from "react-router-dom"
import userStore from "stores/userStore"
import styled from "styled-components"
import { AppColor } from "theme/app-color"
import { checkShowPeriodSection, employeeIsHr, gql, paths } from "utils/helper"
import LogoutModal from "./logout-modal"
import PeriodSection from "components/PeriodSection"
import { ShowDetailOn } from "constants/enums/show-detail-on"
import { isEmpty } from "lodash"
import LoadingModal from "components/LoadingModal"

const Content = styled(Box)`
  padding: 48px 16px 16px 16px;
`

const ContainerFlex = styled.div<{ js: string; row?: boolean; w100?: boolean; mt?: string; al?: string }>`
  display: flex;
  justify-content: ${(props) => props.js};
  flex-direction: ${(props) => (props.row ? "row" : "column")};
  width: ${(props) => (props.w100 ? "100%" : "none")};
  margin-top: ${(props) => (props.mt ? props.mt : "0px")};
  align-items: ${(props) => props.al};
`

const SectionHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
`

const ListContainer = styled(Box)`
  padding: 16px;
`

const CustomIconList = styled(MuiIcon)`
  font-size: 20px;
  margin-left: 8px;
`

const CurrentUser = styled.div`
  width: 100%;
  margin-bottom: 24px;
`

const NotifyBox = styled(Box)`
  display: flex;
  margin-top: 16px;
  margin-bottom: 8px;
  padding: 8px;
  border-radius: 8px;
  background: ${AppColor["System/Info Light"]};
`

const LandingComponent = (props: any) => (
  <>
    <LoadingModal isOpen={props.isLoading} />
    <PageContent
      title="E-claim"
      titleCentered
      onListClickTo="/claim-requests?tab=WAITING"
      extendedTitleHeight={props.extendedTitleHeight.extendedTitleHeight}
      overlapHeight={props.extendedTitleHeight.overlapHeight}
      showDashBoardIcon={true}
    >
      <ListContainer>
        <CurrentUser>
          <Box style={{ position: "relative" }}>
            <Box display="flex" justifyContent="" flexDirection="column">
              <Typography variant="h1" color="White / White">
                สวัสดี
              </Typography>
              <Typography variant="h1" color="White / White">
                {props.currentUserInfo?.employee.firstName + " " + props.currentUserInfo?.employee.lastName}
              </Typography>
              {env.USER_CAN_SEE_REMAINING_BUDGET && (
                <Card style={{ marginTop: "12px", boxShadow: " 0 0 1px" }}>
                  <CardContent
                    style={{ display: "flex", flexDirection: "column", padding: "16px", alignItems: "center" }}
                  >
                    <Typography variant="h4" color={AppColor["Primary/Primary Text"]}>
                      {`วงเงินคงเหลือปี ${props.year}`}
                    </Typography>
                    <div style={{ marginBottom: "16px", marginTop: "16px", display: "flex", gap: "8px" }}>
                      <Icon name="point" />
                      <Typography variant="Body/32" color={AppColor["Text/Primary Text"]} isCurrency>
                        {props.currentPoint}
                      </Typography>
                      <Typography variant="body1" color={AppColor["Text/Primary Text"]}>
                        บาท
                      </Typography>
                    </div>

                    {/* <Typography variant="h4" color={AppColor["Text/Line"]} isCurrency>
                      คิดเป็นเงิน {props.currentPoint} บาท
                    </Typography> */}
                  </CardContent>
                </Card>
              )}
            </Box>
            <Box style={{ position: "absolute", right: 0, top: 0 }}>
              <Link
                to="/history?tab=WAITING"
                style={{ display: "flex", alignItems: "center", color: AppColor["White / White"] }}
              >
                <MuiIcon name="Restore" style={{ fontSize: "14px", marginRight: "2px" }} />
                <Typography variant="subtitle1" color="White / White">
                  ประวัติการเบิก
                </Typography>
              </Link>
            </Box>
          </Box>
        </CurrentUser>
        <SectionHeader style={{ display: "flex" }}>
          <Box mr="8px">
            <Typography variant="h3" color={props.sectionHeaderColor}>
              สวัสดิการพนักงาน
            </Typography>
          </Box>
          <Box mt="2px">
            <Typography variant="subtitle1" color={props.sectionHeaderColor}>
              ( {props.length} รายการ )
            </Typography>
          </Box>
        </SectionHeader>
        {props.validateErrorText && (
          <NotifyBox>
            <MuiIcon
              fontSize="small"
              color={AppColor["System/Info"]}
              name="Info"
              style={{ width: "16px", height: "16px", marginRight: "4px" }}
            ></MuiIcon>
            <Typography variant="Body/12">{props.validateErrorText}</Typography>
          </NotifyBox>
        )}
        {props.notifyBeforeStartAtText && (
          <NotifyBox>
            <MuiIcon
              fontSize="small"
              color={AppColor["System/Info"]}
              name="Info"
              style={{ width: "16px", height: "16px", marginRight: "4px" }}
            ></MuiIcon>
            <Typography variant="Body/12">{props.notifyBeforeStartAtText}</Typography>
          </NotifyBox>
        )}
        <Content>
        {props.isShowPeriodSection && <PeriodSection mt="16px" mb="8px" p="8px" isHaveBorder />}

        <Grid container>
          {props.layouts.map((layout: any, index: number) => (
            <>
              <ContainerFlex js="start" w100>
                <ContainerFlex js="flex-start" al="center" row w100 mt={index === 0 ? "8px" : "32px"}>
                  <Box mr="12px" display="flex" alignItems="center" justifyContent="center">
                    <MuiIcon name={layout.icon} fontSize="small" color={layout.iconColor} />
                  </Box>
                  <Typography variant="h3" color={layout.titleColor}>
                    {layout.title}
                  </Typography>
                </ContainerFlex>
                <Box mt="4px" mb="16px">
                  <Typography variant="subtitle2" color={AppColor["Text/Secondary Text"]}>
                    {layout.description}
                  </Typography>
                </Box>
              </ContainerFlex>
              {layout.sectionList.map((section: any) => (
                <>
                  <Grid container spacing={3}>
                    {section.requestTypeList.map((requestName: string, index: number) => {
                      const request = props.claimRequests[requestName]
                      const budgetValues = props.budgets[requestName]?.list || {}
                      if (request)
                        return (
                          <ClaimCard
                            key={requestName}
                            onClick={() => props.handleClickRequest(requestName)}
                            type={request.type}
                            name={requestName}
                            approvedRequest={request.approvedCount}
                            totalAmount={request.approvedAmount}
                            title={request.title}
                            icon={request.icon}
                            iconColor={request.iconColor}
                            chipTitleColor={request.chipTitleColor}
                            chipBackgroundColor={request.chipBackgroundColor}
                            budget={request.budget ? request.budget.list : null}
                            budgetValues={budgetValues}
                            yearlyBudget={request.type === "ค่ารักษาพยาบาล" ? props.yearlyBudget : {}}
                            
                          />
                        )
                      return <></>
                    })}
                  </Grid>
                </>
              ))}
            </>
          ))}
        </Grid>
      </Content>

        <Box py={6} onClick={props.handleOpenLogoutModal}>
          <Typography
            variant="body1"
            color="Primary/Primary Text"
            align="center"
            style={{ textDecoration: "underline", cursor: "pointer" }}
          >
            ออกจากระบบ
          </Typography>
        </Box>
      </ListContainer>
      <LogoutModal isOpen={props.isLogoutModalOpen} onClose={props.handleCloseLogoutModal} />
    </PageContent>
  </>
)

export const API = {
  GET_CLAIM_REQUEST_CONFIGS: gql`
    query GET_CLAIM_REQUEST_CONFIGS {
      requestConfigs {
        layouts
        requests
        usage
        master
        workflows
      }
    }
  `,
  GET_CURRENT_USER: gql`
    query GET_CURRENT_USER {
      getCurrentUser {
        id
        employee {
          id
          employeeCode
          firstName
          lastName
          role
          grade
          employmentStartDate
          employmentType
          probationPeriod
          probationStatus
          functionalDesignation
          currentPoint
          department
          meta
        }
      }
    }
  `,
  GET_EMPLOYEE_REQUEST_BUDGETS: gql`
    query GET_EMPLOYEE_REQUEST_BUDGETS($employeeId: String!, $childrenName: String!) {
      employeeRequestBudgets(employeeId: $employeeId, childrenName: $childrenName) {
        usage
      }
    }
  `,
  GET_EMPLOYEE_YEARLY_BUDGETS: gql`
    query GET_EMPLOYEE_YEARLY_BUDGETS($filters: JSON) {
      getEmployeeYearlyBudgets(input: { filters: $filters }) {
        id
        employeeCode
        firstName
        lastName
        budget
        dentalBudget
        year
        companyBudget
        companyDentalBudget
      }
    }
  `,
}

const enhancer = compose(
  withStores((stores: any) => ({
    currentUserInfo: stores.userStore.currentUser,
    settingInfo: stores.settingStore.setting,
  })),
  withHooks((props: any, hooks: any) => {
    const { currentUserInfo, settingInfo } = props
    const { useQuery, useEffect, useCallback, useMemo, useState, useLazyQuery, useMutation } = hooks
    const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false)
    const [allBudget, setAllBudgets] = useState({})
    const [yearlyBudget, setYearlyBudget] = useState({})

    const { data: currentUserQry, loading: isLoadingUser, refetch } = useQuery(API.GET_CURRENT_USER, {
      fetchPolicy: "network-only",
      onCompleted: (data: any) => {
        userStore.setCurrentUser(data.getCurrentUser)
      },
      onError: (error: ApolloError) => {
        userStore.setCurrentUser(null)
      },
    })
    const { data, loading: isLoadingClaimRequest } = useQuery(API.GET_CLAIM_REQUEST_CONFIGS, {
      nextFetchPolicy: "network-only",
    })

    const [refetchYearlyBudget, { loading: isLoadingEmpYealyBudget }] = useLazyQuery(API.GET_EMPLOYEE_YEARLY_BUDGETS, {
      onCompleted: (dataBudget: any) => {
        const yearlyBudgetData = dataBudget.getEmployeeYearlyBudgets[0]
        setYearlyBudget(yearlyBudgetData)
      },
      fetchPolicy: "network-only",
    })

    const [refetchBudget, { loading: isLoadingEmpRequestBudget }] = useLazyQuery(API.GET_EMPLOYEE_REQUEST_BUDGETS, {
      onCompleted: (data: any) => {
        const current = data.employeeRequestBudgets.usage
        setAllBudgets(current)
      },
      fetchPolicy: "network-only",
    })
    
    const isLoading = useMemo(() => isLoadingUser || isLoadingClaimRequest || isLoadingEmpYealyBudget || isLoadingEmpRequestBudget, [isLoadingUser, isLoadingClaimRequest, isLoadingEmpYealyBudget, isLoadingEmpRequestBudget])
    const currentUser = useMemo(() => currentUserQry?.getCurrentUser, [currentUserQry])
    const userIsHr = useMemo(() => employeeIsHr(currentUser?.employee.role), [currentUser])
    const currentPoint = useMemo(() => currentUser?.employee.currentPoint || 0, [currentUser])
    const config = useMemo(() => data?.requestConfigs, [data])
    const year = useMemo(() => new Date().getFullYear() + 543, [])
    const showButtonSeeAll = useMemo(() => config?.layouts.allRequests.length > 0, [config])

    const handleClickRequest = useCallback((value: any) => {
      paths.claimDetailPath({ name: value }).push()
    }, [])

    const claimRequestConfigs = useMemo(() => {
      if (config)
        return config.layouts.topRequests.map((name: string) => {
          const request = { ...config.requests[name], name }
          const usage = config.usage[name]
          return {
            ...request,
            ...usage,
          }
        })
      return []
    }, [config])

    const totalClaimRequest = useMemo(() => {
      if (!config?.layouts?.allRequests) return 0

      return config.layouts.allRequests
        .map((allRequest: any) => {
          return allRequest.sectionList.reduce((sum: number, section: any) => sum + section.requestTypeList.length, 0)
        })
        .reduce((sum: number, total: number) => sum + total, 0)
    }, [config])

    const isShowPeriodSection = useMemo(() => {
      if (!settingInfo) {
        return false
      }
      return checkShowPeriodSection(ShowDetailOn.IN_FIRST_PAGE, settingInfo)
    }, [settingInfo])

    const validateErrorText = useMemo(() => {
      const startClaimRequestEnrollment = settingInfo?.startClaimRequestEnrollment
      const endClaimRequestEnrollment = settingInfo?.endClaimRequestEnrollment
      if (
        settingInfo === null ||
        startClaimRequestEnrollment === null ||
        endClaimRequestEnrollment === null ||
        userIsHr
      ) {
        return undefined
      }
      const currentDate = new Date()
      const startDate = new Date(startClaimRequestEnrollment)
      startDate.setFullYear(currentDate.getFullYear())
      const startDateNextYear = new Date(startClaimRequestEnrollment)
      startDateNextYear.setFullYear(currentDate.getFullYear() + 1)
      const endDate = new Date(endClaimRequestEnrollment)
      endDate.setFullYear(currentDate.getFullYear())
      const startDateString =
        startDate.toLocaleDateString("th-TH", {
          year: "numeric",
          month: "long",
          day: "numeric",
          timeZone: "Asia/Bangkok",
        }) +
        " เวลา " +
        startDate.toLocaleTimeString("th-TH", {
          hour: "2-digit",
          minute: "2-digit",
          timeZone: "Asia/Bangkok",
        })
      const startDateNextYearString =
        startDateNextYear.toLocaleDateString("th-TH", {
          year: "numeric",
          month: "long",
          day: "numeric",
          timeZone: "Asia/Bangkok",
        }) +
        " เวลา " +
        startDateNextYear.toLocaleTimeString("th-TH", {
          hour: "2-digit",
          minute: "2-digit",
          timeZone: "Asia/Bangkok",
        })
      if (currentDate < startDate) {
        return `ไม่สามารถเบิกสวัสดิการประจำปีได้กรุณาเข้าใช้งานใหม่วันที่ ${startDateString}`
      }
      if (currentDate > endDate) {
        localStorage.removeItem("isNotDisplayAgain")
        return `ไม่สามารถเบิกสวัสดิการประจำปีได้กรุณาเข้าใช้งานใหม่วันที่ ${startDateNextYearString}`
      }
      return undefined
    }, [settingInfo, userIsHr])

    const handleChangeCheck = useCallback((event: any) => {
      localStorage.setItem("isNotDisplayAgain", event.target.checked.toString())
    }, [])

    const openModalNotify = useCallback(
      (text: string) => {
        // @ts-ignore
        Modal.alert({
          className: "deleteConFirmModal",
          title: "",
          children: (
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
              <Box justifyContent="center" mt="16px">
                <MuiIcon fontSize="large" color={AppColor["System/Info"]} name="Info"></MuiIcon>
              </Box>
              <Box justifyContent="center" mt="16px">
                <Typography variant="h3" color={AppColor["Text/Primary Text"]}>
                  ใกล้หมดเวลาเบิกสวัสดิการประจำปี
                </Typography>
              </Box>
              <Box justifyContent="flexStart" mt="8px" mb="24px">
                <Typography variant="body1" color="Text/Black2">
                  {text}
                </Typography>
                <Box mt="16px">
                  <Field
                    component={Checkbox}
                    name="isNotDisplayAgain"
                    className="isNotDisplayAgain"
                    label="ไม่ต้องแสดงอีก"
                    onChange={handleChangeCheck}
                  />
                </Box>
              </Box>
            </Box>
          ),
          okButtonLabel: "ตกลง",
          okButtonVariant: "contained",
          buttonWidth: "100%",
        })
      },
      [handleChangeCheck],
    )

    const handleOpenLogoutModal = useCallback(() => {
      setIsLogoutModalOpen(true)
    }, [])

    const handleCloseLogoutModal = useCallback(() => {
      setIsLogoutModalOpen(false)
    }, [])

    useEffect(() => {
      if (env.USER_CAN_SEE_REMAINING_BUDGET) {
        const performAction = () => {
          refetch()
        }
        const intervalId = setInterval(performAction, 15000)
        return () => clearInterval(intervalId)
      }
    }, [refetch])

    useEffect(() => {
      if (env.COIN_SOURCE === "native") {
        refetchBudget({
          variables: { employeeId: currentUserInfo.employee.id, childrenName: "" },
        })
      }
    }, [currentUserInfo.employee.id, refetchBudget])

    useEffect(() => {
      if (env.COIN_SOURCE === "native") {
        const variables = {
          filters: {
            code: currentUserInfo.employee.employeeCode,
            fullName: "",
            year: new Date().getFullYear(),
            isApp: true,
          },
        }

        refetchYearlyBudget({ variables: variables })
      }
    }, [currentUserInfo.employee.employeeCode, refetchYearlyBudget])

    const [notifyBeforeStartAtText, setNotifyBeforeStartAtText] = useState()

    useEffect(() => {
      async function notify() {
        const notifyBeforeStartAt = settingInfo?.notifyBeforeStartAt
        const startClaimRequestEnrollment = settingInfo?.startClaimRequestEnrollment
        const endClaimRequestEnrollment = settingInfo?.endClaimRequestEnrollment
        if (
          settingInfo === null ||
          startClaimRequestEnrollment === null ||
          endClaimRequestEnrollment === null ||
          notifyBeforeStartAt === null ||
          currentPoint === 0
        ) {
          return
        }
        const currentDate = new Date()
        const notifyDate = new Date(notifyBeforeStartAt)
        notifyDate.setFullYear(currentDate.getFullYear())
        const startDate = new Date(startClaimRequestEnrollment)
        startDate.setFullYear(currentDate.getFullYear())
        const endDate = new Date(endClaimRequestEnrollment)
        endDate.setFullYear(currentDate.getFullYear())
        const endDateString =
          endDate.toLocaleDateString("th-TH", {
            year: "numeric",
            month: "long",
            day: "numeric",
            timeZone: "Asia/Bangkok",
          }) +
          " เวลา " +
          endDate.toLocaleTimeString("th-TH", {
            hour: "2-digit",
            minute: "2-digit",
            timeZone: "Asia/Bangkok",
          })
        const isNotDisplayAgain = localStorage.getItem("isNotDisplayAgain")
        if (currentDate >= notifyDate && currentDate >= startDate && currentDate <= endDate) {
          setNotifyBeforeStartAtText(`กรุณาส่งเรื่องเบิกสวัสดิการให้เรียบร้อยก่อนวันที่ ${endDateString}`)
        }
        if (
          (isNotDisplayAgain === null || isNotDisplayAgain === "false") &&
          currentDate >= notifyDate &&
          currentDate >= startDate &&
          currentDate <= endDate
        ) {
          openModalNotify(`กรุณาส่งเรื่องเบิกสวัสดิการให้เรียบร้อยก่อนวันที่ ${endDateString}`)
        }
      }
      notify()
    }, [currentPoint, openModalNotify, settingInfo])

    const extendedTitleHeight = useMemo(() => {
      const constants = [isShowPeriodSection, notifyBeforeStartAtText, validateErrorText]
      const constantsLength = constants.filter((constant) => constant).length
      switch (constantsLength) {
        case 1:
          return { extendedTitleHeight: "260px", overlapHeight: "280px" }
        case 2:
          return { extendedTitleHeight: "340px", overlapHeight: "330px" }
        case 3:
          return { extendedTitleHeight: "420px", overlapHeight: "410px" }
        default:
          return { extendedTitleHeight: "180px", overlapHeight: "170px" }
      }
    }, [isShowPeriodSection, notifyBeforeStartAtText, validateErrorText])

    const sectionHeaderColor = useMemo(() => {
      if (env.USER_CAN_SEE_REMAINING_BUDGET) {
        return "White / White"
      }
      return "White / White"
    }, [])

    return {
      isLoading,
      layouts: config?.layouts.allRequests || [],
      claimRequests: config?.requests || {},
      claimRequestConfigs,
      length: totalClaimRequest,
      handleClickRequest,
      currentUserInfo: currentUser,
      userIsHr,
      currentPoint,
      year,
      showButtonSeeAll,
      handleOpenLogoutModal,
      handleCloseLogoutModal,
      isLogoutModalOpen,
      budgets: allBudget,
      yearlyBudget: yearlyBudget || {},
      isShowPeriodSection,
      validateErrorText,
      notifyBeforeStartAtText,
      extendedTitleHeight,
      sectionHeaderColor,
    }
  }),
)

export default enhancer(LandingComponent)
