import * as React from "react"
import styled, { withTheme } from "styled-components/macro"

import { Grid, Hidden, AppBar as MuiAppBar, IconButton as MuiIconButton, Toolbar } from "@material-ui/core"

import { Menu as MenuIcon } from "@material-ui/icons"

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};

  ${(props) => props.theme.breakpoints.up("md")} {
    display: none;
  }
`

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`

type AppBarProps = {
  theme: {}
  onDrawerToggle: React.MouseEventHandler<HTMLElement>
}

const AppBarComponent: React.FC<AppBarProps> = ({ onDrawerToggle }) => (
  <React.Fragment>
    <AppBar position="sticky" elevation={0}>
      <Toolbar>
        <Grid container alignItems="center">
          <Hidden mdUp>
            <Grid item>
              <IconButton color="inherit" aria-label="Open drawer" onClick={onDrawerToggle}>
                <MenuIcon />
              </IconButton>
            </Grid>
          </Hidden>
        </Grid>
      </Toolbar>
    </AppBar>
  </React.Fragment>
)

export default withTheme(AppBarComponent)
