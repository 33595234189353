import React from "react"
import styled from "styled-components/macro"

import { CircularProgress } from "@material-ui/core"

const FullscreenBackdrop = styled.div`
  position: fixed; // Positions the loader relative to the viewport
  top: 0; // Positions the loader at the top of the viewport
  left: 0; // Positions the loader at the left of the viewport
  right: 0; // Stretches the loader to the right of the viewport
  bottom: 0; // Stretches the loader to the bottom of the viewport
  display: flex; // Uses flexbox for centering content
  justify-content: center; // Centers content horizontally
  align-items: center; // Centers content vertically
  background-color: rgba(0, 0, 0, 0.5); // Semi-transparent black backdrop
  z-index: 1000; // Ensures the loader is above other content
`

export const FullScreenLoader = () => {
  return (
    <FullscreenBackdrop>
      <Loader />
    </FullscreenBackdrop>
  )
}

const Root = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
  overflow: hidden;
`

function Loader() {
  return (
    <Root>
      <CircularProgress color="secondary" />
    </Root>
  )
}

export default Loader
