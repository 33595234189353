import React from "react"
import { compose, withHooks } from "enhancers"
import { Box, Field, TextField, Typography } from "components"
import { MathJax, MathJaxContext } from "better-react-mathjax"
import { ReactComponent as EditIcon } from "assets/icon/edit.svg"
import { VisibilityOutlined } from "@material-ui/icons"
import PropTypes from "prop-types"

const LatexInput = (props) => (
  <Box {...props}>
    <Box display="flex" alignItems="center">
      {props.label && (
        <Typography variant="h4" mr={2}>
          {props.label}
        </Typography>
      )}
      {!props.isEdit && <EditIcon onClick={props.switchIsEdit} style={{ marginRight: "8px" }} />}
      {props.isEdit && (
        <VisibilityOutlined onClick={props.switchIsEdit} mr={2} style={{ marginRight: "8px", width: "16px" }} />
      )}
    </Box>
    <Box mt={2} width="100%">
      {!props.isEdit && props.value && (
        <Box style={{ border: "1px solid", borderRadius: "8px" }} p={2}>
          <MathJaxContext version={3}>
            <MathJax>{`${(props.value ?? "").replaceAll("\\\\", "\\")}`}</MathJax>
          </MathJaxContext>
        </Box>
      )}
      {props.isEdit && <Field name={props.name} component={TextField} width="100%" />}
    </Box>
  </Box>
)

const enhancer = compose(
  withHooks((props, hooks) => {
    const { useState, useCallback } = hooks
    const { field, form, ...rest } = props
    const [isEdit, setIsEdit] = useState(false)

    const switchIsEdit = useCallback(() => {
      setIsEdit(!isEdit)
    }, [isEdit])

    return {
      isEdit: isEdit,
      ...rest,
      switchIsEdit,
      value: field.value,
      name: field.name,
    }
  }),
)

LatexInput.propTypes = {
  label: PropTypes.any.isRequired,
  switchIsEdit: PropTypes.any.isRequired,
  isEdit: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
  name: PropTypes.any.isRequired,
}

export default enhancer(LatexInput)
