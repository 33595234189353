import { Step, StepLabel, Stepper } from "@material-ui/core"
import { Box, Icon, Typography } from "components"
import { EnumApprovalStepStatus } from "constants/enums/approval-step-status"
import { EnumClaimRequestStatus } from "constants/enums/claim-request-status"
import dayjs from "dayjs"
import styled from "styled-components"
import { AppColor } from "theme/app-color"

const CustomStepper = styled(Stepper)<{ claimstatus: EnumClaimRequestStatus }>`
  .MuiStepConnector-root.MuiStepConnector-vertical {
    margin-left: 6px;
    padding: 0px;
  }
  .MuiStepConnector-lineVertical {
    min-height: 26px;
    margin-top: -9px;
    margin-bottom: -9px;
    width: 1px;
  }
`

const CustomStepLabel = styled(StepLabel)`
  .MuiStepIcon-root {
    font-size: 14px;
  }
`

const MappingStatusLabel = (status: EnumClaimRequestStatus) => {
  switch (status) {
    case EnumClaimRequestStatus.DRAFT:
      return "แบบร่าง"
    case EnumClaimRequestStatus.APPROVED:
      return "อนุมัติแล้ว"
    case EnumClaimRequestStatus.WAITING:
      return "รออนุมัติ"
    case EnumClaimRequestStatus.WAITING_PAYMENT_CONFIRM:
      return "รอวันชำระเงิน"
    case EnumClaimRequestStatus.REJECTED:
      return "ไม่อนุมัติ"
    case EnumClaimRequestStatus.CANCELED:
      return "ยกเลิก"
    default:
      break
  }
}

const StatusIcon = styled(Icon)`
  background-color: ${AppColor["Primary/Line"]};
  z-index: 1;
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
`

function StatusStepIcon(props: any) {
  const { active, completed, error } = props
  if (error && completed) {
    return <StatusIcon name="stop_circle" width="14px" height="14px" color={AppColor["Other/Danger"]} />
  }

  if (error) {
    return <StatusIcon name="Cancel" width="14px" height="14px" color={AppColor["Other/Danger"]} />
  }

  if (active) {
    return <StatusIcon name="PauseCircleFilled" width="14px" height="14px" color="#FFE100" />
  }

  if (completed) {
    return <StatusIcon name="CheckCircle" width="14px" height="14px" color={AppColor["Success"]} />
  }
  return <StatusIcon name="adjust" width="14px" height="14px" color={AppColor["Text/Gray Success"]} />
  // return <StatusStepIconRoot ownerState={{ completed, active, error }} className={className}></StatusStepIconRoot>
}

interface StepStatusProps {
  claimStatus: EnumClaimRequestStatus
  workflows: []
  workflowStep?: number
  canceled?: string
}

export const StepStatus = (props: StepStatusProps) => {
  const { workflowStep, claimStatus, workflows, canceled } = props
  const labelProps: {
    optional?: React.ReactNode
    active?: boolean
    error?: boolean
    completed?: boolean
  } = { active: false, error: true, completed: true }

  return (
    <>
      {claimStatus === EnumClaimRequestStatus.CANCELED ? (
        <CustomStepper orientation="vertical" style={{ padding: 0, background: "none" }} claimstatus={claimStatus}>
          <Step>
            <CustomStepLabel {...labelProps} StepIconComponent={StatusStepIcon}>
              <Box display="flex" alignItems="center">
                <Box minWidth="80px" mr="8px">
                  <Typography color="White / White" variant="body1">
                    ยกเลิก
                  </Typography>
                </Box>
                <Typography color="White / White" variant="subtitle1">
                  ({canceled})
                </Typography>
              </Box>
            </CustomStepLabel>
          </Step>
        </CustomStepper>
      ) : (
        <CustomStepper orientation="vertical" style={{ padding: 0, background: "none" }} claimstatus={claimStatus}>
          {workflows.map((flow: any, index: number) => {
            const labelProps: {
              optional?: React.ReactNode
              active?: boolean
              error?: boolean
              completed?: boolean
            } = {}
            if (
              flow.status === EnumApprovalStepStatus.WAITING ||
              flow.status === EnumApprovalStepStatus.WAITING_PAYMENT_CONFIRM
            )
              labelProps.active = true
            else if ([EnumApprovalStepStatus.REJECTED].includes(flow.status)) labelProps.error = true
            else if (flow.status === EnumApprovalStepStatus.APPROVED) {
              labelProps.completed = true
              labelProps.active = false
            }
            return (
              <Step key={flow.seq}>
                <CustomStepLabel {...labelProps} StepIconComponent={StatusStepIcon}>
                  <Box display="flex" alignItems="center">
                    <Box minWidth="80px" mr="8px">
                      <Typography color="White / White" variant="body1">
                        {claimStatus === EnumClaimRequestStatus.REJECTED &&
                        flow.status === EnumClaimRequestStatus.WAITING
                          ? "-"
                          : MappingStatusLabel(flow.status)}
                      </Typography>
                    </Box>
                    <Box minWidth="80px" mr="8px">
                      <Typography color="White / White" variant="subtitle1">
                        ({flow.approverTxt})
                      </Typography>
                    </Box>
                    <Typography color="White / White" variant="subtitle1">
                      {flow.approvedAt && dayjs(flow.approvedAt).format("DD/MM/YYYY HH:mm")}
                    </Typography>
                  </Box>
                </CustomStepLabel>
              </Step>
            )
          })}
        </CustomStepper>
      )}
    </>
  )
}
