import { Box, Button, Grid, IconButton, Modal, Notification, Typography, MuiIcon, Form, Icon } from "components"
import { ClaimCard } from "components/advance/ClaimCard"
import { StepStatus } from "components/advance/StepStatus"
import { UploadDocumentCard } from "components/advance/UploadDocumentCard"
import { EnumClaimRequestStatus } from "constants/enums/claim-request-status"
import dayjs from "dayjs"
import { compose, withConfirmGoBack, withConfirmRefresh, withFormik, withHooks, withStores } from "enhancers"
import { Field } from "pages/main/claim-detail/form-field"
import { Link, useHistory } from "react-router-dom"
import styled from "styled-components"
import { AppColor } from "theme/app-color"
import { employeeIsHr, gql, isJson, paths, stringToDate, toCurrency } from "utils/helper"
import ApproveModal from "./approve-modal"
import RejectModal from "./reject-modal"
import { DefaultPreviewComponent } from "pages/main/claim-detail/form-field/preview/Default"
import { InfoValuesConfig } from "pages/main/claim-detail"
import { INPUT_TYPE } from "constants/enums/input-type"
import { OptionValue } from "constants/enums/option-value"
import { SummaryComponent } from "pages/main/claim-detail/SummaryComponent"
import BudgetComponent from "../BudgetComponent"
import { functions, env } from "configs"
import { IconName } from "components/common/Icon"
import { RejectReasonComponent } from "../edit/RejectReasonComponent"
import LoadingModal from "components/LoadingModal"
import { get, isEmpty } from "lodash"
import { EnumApprovalStatus } from "constants/enums/approval-status"
import { EnumClaimAction } from "constants/enums/claim-action"
import { HR } from "constants/enums/employee-role"

const DetailStatusContainer = styled("div")`
  position: relative;
  background-color: ${AppColor["Primary/Background"]};
  height: auto;
  padding: 16px;
`

const ClaimDetail = styled("div")`
  position: relative;
  background: ${AppColor["White / White"]};
  height: auto;
  padding: 16px;
  box-shadow: 4px 0px 8px ${AppColor["Text/Background"]};
`

const CloseButton = styled(IconButton)`
  color: ${AppColor["White / White"]};
  padding: 0px;
`

const Title = styled("div")`
  margin-top: 24px;
`

const TopBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const RejectActionButton = styled(Button)`
  width: 100%;
  background-color: ${AppColor["White / White"]};
  &:focus {
    background-color: ${AppColor["White / White"]};
  }
  &:active {
    background-color: ${AppColor["White / White"]};
  }
  &:hover {
    background-color: ${AppColor["System/Error Light Hover"]};
  }
`

const ActionButton = styled(Button)`
  width: 100%;
`

const DetailBox = styled(Box)`
  margin-top: 16px;
`

const FlexBox = styled(Box)<{ mb?: string; mt?: string }>`
  display: flex;
  margin-bottom: ${(props) => (props.mb ? props.mb : "0px")};
  margin-top: ${(props) => (props.mt ? props.mt : "0px")};
`

const FrontBox = styled(Box)<{ mr?: string }>`
  min-width: 100px;
  margin-right: ${(props) => (props.mr ? props.mr : "0px")};
`

const FooterContainer = styled("div")`
  padding: 16px;
  background-color: ${AppColor["White / White"]};
`

const WarningIcon = styled(MuiIcon)`
  font-size: 16px;
  margin-right: 4px;
`

const WarningBox = styled(Box)<{ mt?: string; mb?: string }>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0px 8px;
  height: 32px;
  background-color: ${AppColor["System/Warning Light"]};
  border-radius: 8px;
  margin-top: ${(props) => (props.mt ? props.mt : "0px")};
  margin-bottom: ${(props) => (props.mb ? props.mb : "0px")};
`

const LightBlueBox = styled(Box)`
  background: ${AppColor["White / White"]};
  border-radius: 4px;
  margin-top: 16px;
`

const List = styled.ul`
  margin: 0px 0px 0px -16px;
`

const ApprovalPageComponent = (props: any) => (
  <>
    <LoadingModal isOpen={!props.isLoaded || props.imageLoading} />
    <LoadingModal isOpen={props.approveRequestLoading} title="กำลังส่งคำขอการเบิก" />
    {props.defaultTransferDate && props.isLoaded && (
      <ApproveModal
        title={props.title}
        isOpen={props.isApproveModalOpen}
        onClose={props.handleCloseApproveModal}
        onConfirm={props.handleConfirmApprove}
        amount={props.requestAmount}
        isInsurance={props.isInsurance}
        defaultTransferDate={props.defaultTransferDate}
        submitTransferFast={props.submitTransferFast}
        master={props.master}
        isPassAwayRequest={props.isPassAwayRequest}
        name={props.name}
        isPaymentDate={props.isApprovePaymentDate}
      />
    )}

    <RejectModal
      title={props.title}
      isOpen={props.isRejectModalOpen}
      onClose={props.handleCloseRejectModal}
      onConfirm={props.handleConfirmReject}
      amount={props.requestAmount}
      rejectReasons={props.rejectReasons}
    />
    {props.isLoaded && (
      <>
        <Box style={{ backgroundColor: AppColor["Primary/Background"] }}>
          <Box minHeight={200} minWidth={357} maxWidth={752} overflow="hidden" mx="auto">
            <DetailStatusContainer>
              <TopBox>
                <Typography variant="h2" color="White / White">
                  รายละเอียดการเบิก
                </Typography>
                <CloseButton onClick={props.handleClosePage}>
                  <MuiIcon name="Close" style={{ fontSize: "24px" }} />
                </CloseButton>
              </TopBox>
              <DetailBox>
                <FlexBox mb="8px">
                  <FrontBox mr="16px">
                    <Typography variant="body1" color="White / White">
                      ผู้เบิก
                    </Typography>
                  </FrontBox>
                  <Typography variant="body1" color="White / White">
                    {props.createdBy}
                  </Typography>
                </FlexBox>
                <FlexBox mb="8px">
                  <FrontBox mr="16px">
                    <Typography variant="body1" color="White / White">
                      ฝ่าย/สังกัด
                    </Typography>
                  </FrontBox>
                  <Typography variant="body1" color="White / White">
                    {props.affiliation}
                  </Typography>
                </FlexBox>
                <FlexBox mb="8px">
                  <FrontBox mr="16px">
                    <Typography variant="body1" color="White / White">
                      กลุ่มพนักงาน
                    </Typography>
                  </FrontBox>
                  <Typography variant="body1" color="White / White">
                    {props.employeeGroup}
                  </Typography>
                </FlexBox>
                <FlexBox mb="8px">
                  <FrontBox mr="16px">
                    <Typography variant="body1" color="White / White">
                      เบอร์โทร
                    </Typography>
                  </FrontBox>
                  <Typography variant="body1" color="White / White">
                    {props.phoneNumber}
                  </Typography>
                </FlexBox>
                <FlexBox mb="8px">
                  <FrontBox mr="16px">
                    <Typography variant="body1" color="White / White">
                      อีเมล
                    </Typography>
                  </FrontBox>
                  <Typography variant="body1" color="White / White">
                    {props.email}
                  </Typography>
                </FlexBox>
                <FlexBox mb="8px">
                  <FrontBox mr="16px">
                    <Typography variant="body1" color="White / White">
                      เลขอ้างอิง
                    </Typography>
                  </FrontBox>
                  <Typography variant="body1" color="White / White">
                    {props.claimId}
                  </Typography>
                </FlexBox>
                <FlexBox mb="8px">
                  <FrontBox mr="16px">
                    <Typography variant="body1" color="White / White">
                      วันที่เบิก
                    </Typography>
                  </FrontBox>
                  <Typography variant="body1" color="White / White">
                    {dayjs(props.createdAt).format("DD/MM/YYYY")}
                  </Typography>
                </FlexBox>
                <FlexBox mb="8px">
                  <FrontBox mr="16px">
                    <Typography variant="body1" color="White / White">
                      สถานะ
                    </Typography>
                  </FrontBox>
                  <StepStatus
                    claimStatus={props.claimStatus}
                    workflows={props.workflows}
                    workflowStep={props.workflowStep}
                  />
                </FlexBox>
                <FlexBox>
                  <FrontBox mr="16px">
                    <Typography variant="body1" color="White / White">
                      แก้ไขล่าสุด
                    </Typography>
                  </FrontBox>
                  <Typography variant="body1" color="White / White">
                    {dayjs(props.updatedAt).format("DD/MM/YYYY")}
                  </Typography>
                </FlexBox>
              </DetailBox>
              {props.claimStatus === EnumClaimRequestStatus.APPROVED && props.transferDate && (
                <LightBlueBox padding="8px 24px">
                  <Box display="flex" flexDirection="column" textAlign="center">
                    <Typography variant="body1" color="Primary/Line">
                      {`${props.mappingPaymentTypeMessage} ${props.requestAmount} บาท`}
                    </Typography>
                    <Typography variant="body1" color="Primary/Line">
                      {`เข้าบัญชีพนักงานวันที่ ${dayjs(props.transferDate).format("DD/MM/YYYY")}`}
                    </Typography>
                    {props.transferFast && (
                      <Typography variant="body1" color="Primary/Line">
                        [จ่ายเป็นกรณีเร่งด่วน]
                      </Typography>
                    )}
                  </Box>
                </LightBlueBox>
              )}
              {props.claimStatus === EnumClaimRequestStatus.REJECTED && (
                <LightBlueBox padding="8px 16px">
                  <FlexBox>
                    <FrontBox mr="8px">
                      <Typography variant="body1" color={AppColor["Primary/Line"]}>
                        เหตุผล:
                      </Typography>
                    </FrontBox>
                    <Typography variant="body1" color={AppColor["Primary/Line"]}>
                      {props.rejectReason || "-"}
                    </Typography>
                  </FlexBox>
                  <FlexBox mt="8px">
                    <FrontBox mr="8px">
                      <Typography variant="body1" color={AppColor["Primary/Line"]}>
                        หมายเหตุ:
                      </Typography>
                    </FrontBox>
                    <Typography variant="body1" color={AppColor["Primary/Line"]}>
                      {props.rejectRemark || "-"}
                    </Typography>
                  </FlexBox>
                </LightBlueBox>
              )}
            </DetailStatusContainer>
          </Box>
        </Box>
        <Box minHeight={200} minWidth={357} maxWidth={752} overflow="hidden" mx="auto">
          <ClaimDetail>
            {props.currentUserIsHr && props.isInstead && (
              <>
                <WarningBox mt="8px" mb="24px">
                  <WarningIcon name="Warning" color={AppColor["Error/Error Text"]}></WarningIcon>
                  <Typography variant="body2" color={AppColor["Text/Primary Text"]}>
                    กำลังทำรายการแทนพนักงานคนอื่น
                  </Typography>
                </WarningBox>
              </>
            )}
            <Box mt="8px">
              <ClaimCard
                title={props.title}
                type={props.type}
                name={props.name}
                approvedRequest={props.approvedCount}
                totalAmount={props.approvedAmount}
                icon={props.icon}
                iconColor={props.iconColor}
                chipTitleColor={props.chipTitleColor}
                chipBackgroundColor={props.chipBackgroundColor}
                fullSize
                displayOnly
              />
            </Box>
            {props.canEdit && props.rejectReason && (
              <RejectReasonComponent
                rejectedBy={props.rejectedBy}
                rejectReason={props.rejectReason}
                remark={props.rejectRemark}
              />
            )}
            {props.summary && props.isHrApproved && (
              <SummaryComponent
                summary={props.summary}
                total={props.approvedAmount}
                inputs={props.inputs}
                values={props.detailValues}
              />
            )}
            <Title>
              <Box display="flex" textAlign="center">
                {env.ICON_CONFIG.DETAIL_LABEL && (
                  <Icon
                    style={{ marginRight: "8px" }}
                    name={env.ICON_CONFIG.DETAIL_LABEL as IconName}
                    color={AppColor["Primary/Primary Text"]}
                    width={24}
                    height={24}
                  />
                )}
                <Typography variant="h3"> {props.canEdit ? "กรอกรายละเอียดการเบิก" : "รายละเอียดการเบิก"}</Typography>
              </Box>
            </Title>
            <Form>
              <Grid container style={{ paddingTop: 16 }}>
                <Grid item xs={12} key="select-employee">
                  <Typography variant="body1" color="Text/Gray Preview">
                    ผู้ขอเบิก
                  </Typography>
                  <Field
                    isPreview={true}
                    component="SELECT"
                    label=""
                    name="requesterId"
                    value={props.requesterValues}
                  />
                </Grid>

                {props.claimFor && props.claimFor !== "Self" && (
                  <Grid item xs={12} key="select-relations" style={{ paddingTop: 16 }}>
                    <DefaultPreviewComponent component="INPUT" label="เบิกให้" value={props.claimFor.nameTh} />
                  </Grid>
                )}
                {props.inputs?.map((input: any) => (
                  <>
                    {!props.canEdit && input.type === INPUT_TYPE.LABEL ? (
                      <></>
                    ) : (
                      <Grid
                        item
                        xs={12}
                        key={`${input.name}-5`}
                        style={{ paddingTop: input.type === INPUT_TYPE.SUM_AMOUNT ? 0 : 16 }}
                      >
                        <Field
                          fast={!(input.type === INPUT_TYPE.RELATION)}
                          isPreview={props.isPreview || input.readOnly}
                          component={input.type}
                          label={input.title}
                          name={input.name}
                          disabled={input.disabled}
                          options={input.options}
                          unit={input.unit}
                          placeholder={input.placeholder}
                          required={input.required}
                          icon={input.icon}
                          iconColor={input.iconColor}
                          helperText={input.remark}
                          master={props.master}
                          requester={props.requester}
                          auto={input.auto}
                          requestName={props.name}
                          values={props.values}
                          setFieldValue={props.setFieldValue}
                          value={props.detailValues[input.name]}
                          defaultValue={props.initialValues[input.name]}
                          helperTextColor={input.remarkColor}
                          maxDate={input.maxDate}
                          minDate={input.minDate}
                          filterOptions={input.filterOptions}
                          backgroundColor={input.backgroundColor}
                          textColor={input.textColor}
                          setAdditionalList={props.setDiseaseList}
                        />
                      </Grid>
                    )}
                  </>
                ))}
                {props.showBudget && (
                  <BudgetComponent
                    budgetDetail={props.budgetDetail.detail}
                    budgetValues={props.budgetValues}
                    year={props.year}
                    values={props.values}
                    yearlyBudget={props.yearlyBudget}
                    type={props.name}
                  />
                )}
              </Grid>
            </Form>

            {props.documents.length > 0 && !(props.isPreview && props.hasNoDocuments) && (
              <UploadDocumentCard
                documents={props.documents}
                values={props.documentValues}
                onChange={props.handleFilesChange}
                canDelete={false}
                isPreview={props.isPreview}
                mb="8px"
                onLoading={(loading) => props.setImageLoading(loading)}
              />
            )}
          </ClaimDetail>

          {props.showFooter && (
            <FooterContainer>
              <Typography variant="h3" color="Text/Primary Text">
                ผลการพิจารณา
              </Typography>
              {props.canSaveDraft && (
                <FlexBox mt="16px">
                  <ActionButton onClick={props.handleClickSaveDraft} variant="outlined" disabled={props.imageLoading}>
                    บันทึกร่าง
                  </ActionButton>
                </FlexBox>
              )}

              {props.canApprove && (
                <FlexBox mt="16px" mb="8px">
                  <RejectActionButton
                    variant="outlined"
                    color={AppColor["Other/Danger"]}
                    style={{ borderColor: AppColor["Other/Danger"] }}
                    onClick={props.handleOpenRejectModal}
                    mr="16px"
                    disabled={props.disabledRejectButton}
                  >
                    ตรวจสอบเพิ่มเติม
                  </RejectActionButton>
                  <ActionButton
                    onClick={props.handleOpenApproveModal}
                    variant="contained"
                    disabled={props.disabledApproveButton}
                  >
                    {props.tab === EnumClaimRequestStatus.WAITING_PAYMENT_CONFIRM ? "กำหนดวันชำระเงิน" : "อนุมัติ"}
                  </ActionButton>
                </FlexBox>
              )}
            </FooterContainer>
          )}
        </Box>
      </>
    )}
  </>
)

const API = {
  GET_CLAIM_REQUEST_DETAIL: gql`
    query GET_CLAIM_REQUEST_DETAIL($id: String!, $status: String!) {
      claimRequest(id: $id, status: $status) {
        workflowSeq
        id
        type
        status
        config
        info
        employeeId
        employee
        createdAt
        updatedAt
        createdBy
        referenceId
        rejectReason
        remark
        transferDate
        canceled
        currentWorkflow
        approvedCount
        approvedAmount
        rejectedBy
        myApprovalStatus
      }
    }
  `,
  APPROVE_REQUEST: gql`
    mutation APPROVE_REQUEST(
      $requestId: String!
      $isApprove: Boolean!
      $createdBy: String!
      $rejectReason: JSON
      $remark: String
      $transferDate: DateTime
      $seq: Int!
      $isTransferFast: Boolean
      $approveValues: JSON
      $info: JSON!
      $requestName: String!
      $employeeId: String!
      $diseases: [String!]
    ) {
      approveClaimRequest(
        input: {
          requestId: $requestId
          isApprove: $isApprove
          createdBy: $createdBy
          rejectReason: $rejectReason
          remark: $remark
          transferDate: $transferDate
          seq: $seq
          isTransferFast: $isTransferFast
          approveValues: $approveValues
          info: $info
          requestName: $requestName
          employeeId: $employeeId
          diseases: $diseases
        }
      ) {
        workflowSeq
        id
        type
        status
        config
        info
        createdAt
      }
    }
  `,
  VALIDATE_REQUEST: gql`
    mutation VALIDATE_REQUEST($info: JSON!, $employeeId: String!, $requestName: String!, $requestId: String) {
      validateClaim(
        inputs: { info: $info, employeeId: $employeeId, requestName: $requestName, requestId: $requestId }
      ) {
        hasErrorMessage
      }
    }
  `,
  GET_CLAIM_REQUEST_CONFIGS: gql`
    query GET_CLAIM_REQUEST_CONFIGS {
      requestConfigs {
        master
      }
    }
  `,
  GET_EMPLOYEE_REQUEST_BUDGETS: gql`
    query GET_EMPLOYEE_REQUEST_BUDGETS($employeeId: String!, $childrenName: String!) {
      employeeRequestBudgets(employeeId: $employeeId, childrenName: $childrenName) {
        usage
      }
    }
  `,
  GET_EMPLOYEE_YEARLY_BUDGETS: gql`
    query GET_EMPLOYEE_YEARLY_BUDGETS($filters: JSON) {
      getEmployeeYearlyBudgets(input: { filters: $filters }) {
        id
        employeeCode
        firstName
        lastName
        budget
        dentalBudget
        year
        companyBudget
        companyDentalBudget
      }
    }
  `,
  GET_REMOTE_BUDGET: gql`
    query GET_REMOTE_BUDGET($name: String!, $type: String!, $values: JSON!) {
      remoteBudget(input: { name: $name, type: $type, values: $values }) {
        remainingBudget
        maximumBudget
      }
    }
  `,
  SAVE_DRAFT: gql`
    mutation SAVE_DRAFT(
      $type: String!
      $info: JSON!
      $employeeId: String!
      $requestId: String!
      $isHr: Boolean!
      $status: String!
    ) {
      updateDraftClaimRequest(
        input: {
          type: $type
          info: $info
          employeeId: $employeeId
          requestId: $requestId
          isHr: $isHr
          status: $status
        }
      ) {
        id
        referenceId
        createdAt
      }
    }
  `,
}

const enhancer = compose(
  withFormik(),
  withStores((stores: any) => ({
    currentUserInfo: stores.userStore.currentUser,
  })),
  withConfirmRefresh(),
  withConfirmGoBack(),

  withHooks((props: any, hooks: any) => {
    const {
      currentUserInfo,
      disableConfirm,
      disableConfirmRefresh,
      enableConfirmRefresh,
      setValues,
      values,
      setFieldValue,
      setInitialValues,
    } = props

    const {
      useParams,
      useQuery,
      useCallback,
      useEffect,
      useState,
      useMutation,
      useMemo,
      useLazyQuery,
      useUrlParam,
      usePrevious,
    } = hooks
    const queryParams = useUrlParam()
    const { tab, filters = {} } = queryParams || {}
    const { id } = useParams()
    const [imageLoading, setImageLoading] = useState(true)
    const [hasNoDocuments, setHasNoDocument] = useState(false)
    const [isDetailLoaded, setIsDetailLoaded] = useState(false)
    const history = useHistory()

    const { data: configs } = useQuery(API.GET_CLAIM_REQUEST_CONFIGS)
    const {
      data,
      refetch,
      loading: detailLoading,
    } = useQuery(API.GET_CLAIM_REQUEST_DETAIL, {
      variables: {
        id,
        status: tab,
      },
      fetchPolicy: "network-only",
      onCompleted: (data: any) => {
        // TODO: check loading
        const documentsValues = data.claimRequest.info.values.documents
        const documentHasValues = Object.keys(documentsValues).filter((key) => documentsValues[key].length > 0)
        if (documentHasValues.length === 0) {
          setImageLoading(false)
          setHasNoDocument(true)
        }
      },
      onError: (error: any) => {
        setImageLoading(false)
        const errorMessage = isJson(error.message) ? JSON.parse(error.message)[0] : error.message
        // @ts-ignore
        Modal.alert({
          className: "deleteConFirmModal",
          title: "",
          children: (
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
              <Box mb="16px">
                <MuiIcon fontSize="large" color={AppColor["Warning"]} name="Warning"></MuiIcon>
              </Box>
              <Box mb="16px">
                <Typography variant="body1" color="Text/Black2">
                  {errorMessage}
                </Typography>
              </Box>
            </Box>
          ),
          okButtonLabel: "ตกลง",
          okButtonVariant: "contained",
          buttonWidth: "100%",
          onOk: (confirmProps: any) => {
            confirmProps.close()
            disableConfirm()
            setDisabledApproveButton(false)
            setDisabledRejectButton(false)
            paths.landingPath().push()
          },
        })
      },
    })

    const [approveRequest, { error: approveError, loading: approveRequestLoading }] = useMutation(API.APPROVE_REQUEST)
    const [validateRequest, { loading: validateClaimLoading }] = useMutation(API.VALIDATE_REQUEST)
    const [disabledApproveButton, setDisabledApproveButton] = useState(false)
    const [disabledRejectButton, setDisabledRejectButton] = useState(false)

    const [isApproveModalOpen, setIsApproveModalOpen] = useState(false)
    const [isRejectModalOpen, setIsRejectModalOpen] = useState(false)
    const [submitTransferFast, setSubmitTransferFast] = useState(false)
    const [isPreview, setIsPreview] = useState(false)

    const [infoValues, setInfoValues] = useState({
      inputs: {},
      documents: {},
    })
    const [detailValues, setDetailValues] = useState({})
    const [allBudgets, setAllBudgets] = useState()
    const [yearlyBudget, setYearlyBudget] = useState()

    const [normalBudgets, setNormalBudgets] = useState()
    const [remoteBudgets, setRemoteBudgets] = useState()
    const [diseases, setDiseases] = useState([])

    const employee = useMemo(
      () => ({
        ...data?.claimRequest?.employee,
        budget: yearlyBudget?.budget,
        dentalBudget: yearlyBudget?.dentalBudget,
      }),
      [data?.claimRequest?.employee, yearlyBudget],
    )

    const [refetchBudget] = useLazyQuery(API.GET_EMPLOYEE_REQUEST_BUDGETS, {
      onCompleted: (budgetData: any) => {
        if (data.claimRequest) {
          const current = budgetData.employeeRequestBudgets.usage[data?.claimRequest.type]
          setNormalBudgets(current)
        }
      },
      fetchPolicy: "network-only",
    })

    const [refetchRemoteBudget] = useLazyQuery(API.GET_REMOTE_BUDGET, {
      onCompleted: (budgetData: any) => {
        setRemoteBudgets(budgetData)
        setFieldValue("calculatedRemoteBudget", budgetData)
      },
      fetchPolicy: "network-only",
    })

    useEffect(() => {
      if (remoteBudgets) {
        setAllBudgets(remoteBudgets)
      } else {
        setAllBudgets(normalBudgets)
      }
    }, [allBudgets, normalBudgets, remoteBudgets])

    const [refetchYearlyBudget] = useLazyQuery(API.GET_EMPLOYEE_YEARLY_BUDGETS, {
      onCompleted: (dataBudget: any) => {
        const yearlyBudgetData = dataBudget.getEmployeeYearlyBudgets[0]
        setYearlyBudget(yearlyBudgetData)
      },
      fetchPolicy: "network-only",
    })

    useEffect(() => {
      fetchBudgetData(employee, values, refetchBudget)
    }, [refetchBudget, values.children_name, employee, values])

    useEffect(() => {
      fetchYearlyBudget(employee, data, refetchYearlyBudget)
    }, [refetchYearlyBudget, data, data?.claimRequest.info.type, employee])

    const currentUser = useMemo(() => {
      return currentUserInfo?.employee
    }, [currentUserInfo])

    const currentUserLv = useMemo(() => {
      if (currentUser && !isEmpty(currentUser.meta)) {
        if (functions.validate["findEmployeeLv"] === undefined) {
          return "unit_name_lv6"
        }
        return functions.validate["findEmployeeLv"](currentUser)
      }
      return "unit_name_lv6"
    }, [currentUser])

    const overHr = useMemo(() => {
      if (currentUserLv) return currentUserLv !== "unit_name_lv6"
      return false
    }, [currentUserLv])

    const currentUserIsHr = useMemo(() => (currentUser ? employeeIsHr(currentUser.role) : false), [currentUser])
    const master = useMemo(() => {
      if (configs) return getValueOrDefault(configs.requestConfigs.master, {})
    }, [configs])

    const approveParams = useMemo(
      () => ({
        requestId: id,
        createdBy: currentUser?.id,
      }),
      [id, currentUser],
    )

    const isPassAwayRequest = useMemo(() => {
      const requestTitle = data?.claimRequest.info.title
      const passAwayRequest = checkIsPassaway(requestTitle)
      return passAwayRequest
    }, [data?.claimRequest.info.title])

    const currentWorkflowSeq = useMemo(() => data?.claimRequest.currentWorkflow, [data?.claimRequest.currentWorkflow])

    const currentActionWorkflow = useMemo(() => {
      const workflows = data?.claimRequest.workflowSeq

      if (workflows) return workflows.find((workflow: any) => workflow.seq === currentWorkflowSeq)
    }, [currentWorkflowSeq, data?.claimRequest.workflowSeq])

    const previousActionWorkflow = useMemo(() => {
      const workflows = data?.claimRequest.workflowSeq

      if (workflows) {
        const currentActionWorkflowIndex = workflows?.findIndex((workflow: any) => workflow.seq === currentWorkflowSeq)
        return workflows[currentActionWorkflowIndex - 1]
      }
    }, [currentWorkflowSeq, data?.claimRequest.workflowSeq])

    const nextActionWorkflow = useMemo(() => {
      const workflows = data?.claimRequest.workflowSeq

      if (workflows) {
        const currentActionWorkflowIndex = workflows?.findIndex((workflow: any) => workflow.seq === currentWorkflowSeq)
        return workflows[currentActionWorkflowIndex + 1]
      }
    }, [currentWorkflowSeq, data?.claimRequest.workflowSeq])

    const requestRejected = useMemo(() => data?.claimRequest.status === EnumClaimRequestStatus.REJECTED, [data])

    const requestApproved = useMemo(
      () => data?.claimRequest.status === EnumClaimRequestStatus.APPROVED,
      [data?.claimRequest.status],
    )

    const isApprovePaymentDate = useMemo(
      () => currentActionWorkflow?.statusNextStep === EnumClaimRequestStatus.APPROVED,
    )

    const tabIsNotWaiting = useMemo(
      () => tab !== EnumClaimRequestStatus.WAITING && tab !== EnumClaimRequestStatus.WAITING_PAYMENT_CONFIRM,
      [tab],
    )

    const showFooter = useMemo(() => {
      return isShowFooter(requestApproved, requestRejected, tabIsNotWaiting)
    }, [requestApproved, requestRejected, tabIsNotWaiting])

    const approvalSection = useMemo(() => {
      return mapApprovalSection(requestRejected, requestApproved, data)
    }, [requestRejected, data, requestApproved])

    const previousValues = usePrevious(values)
    useEffect(() => {
      let hasChange = false
      const budgetDetailArr = get(data?.claimRequest, "config.budget.detail", [])
      const budgetDetail = budgetDetailArr[0]
      const remoteBudgetDependencies = get(budgetDetail, "remoteBudgetInfoDependencies", false)
      const remoteBudget = get(budgetDetail, "remoteBudget", false)
      if (budgetDetail && remoteBudgetDependencies && remoteBudget) {
        budgetDetail.remoteBudgetInfoDependencies.forEach((field: any) => {
          if (hasChange) {
            return
          } else {
            if (values[field] !== previousValues[field]) {
              hasChange = true
            }
          }
        })
      }
      if (hasChange) {
        refetchRemoteBudget({
          variables: {
            type: data?.claimRequest?.type,
            name: data?.claimRequest?.config?.budget?.detail[0]?.remoteBudget,
            values: { ...values, employeeId: employee?.id },
          },
        })
      }
    }, [
      data?.claimRequest,
      data?.claimRequest?.config?.budget?.detail,
      employee?.id,
      previousValues,
      refetchRemoteBudget,
      values,
    ])

    const hasFile = useMemo(() => data?.claimRequest.info.documents.filter((doc: any) => doc.file) > 0, [data])

    const isInstead = useMemo(() => checkInstead(data), [data])

    const defaultTransferDate = useMemo(() => {
      return getTranferDate(data)
    }, [data])

    const requestAmount = useMemo(() => {
      const history = data?.claimRequest
      let amountFieldName = history?.info.amountFieldName

      const showDefaultAmountField = currentWorkflowSeq === 0 || history?.type === "child_support"
      const amountFromInfo = getAmountFieldNameForApprove(showDefaultAmountField, history, amountFieldName)
      const func = functions.calculate[history?.info.calculateAmount]
      amountFieldName = getAmountFieldName(func, history, amountFieldName)

      if (showDefaultAmountField) {
        return getValueOrDefault(amountFromInfo, 0)
      }
      return getValueOrDefault(infoValues?.inputs[amountFieldName], 0)
    }, [currentWorkflowSeq, data?.claimRequest, infoValues?.inputs])

    const isInsurance = useMemo(() => checkInsurance(data), [data])

    const canEdit = useMemo(() => {
      // only bam
      const unitNameLv5 = get(currentUser, "meta.unit_name_lv5")
      const lvCode = get(currentUser, "meta.employeeLevelCode")
      const department = get(currentUser, "department")
      const unitIsHR = unitNameLv5 === "กลุ่มค่าตอบแทนและสวัสดิการ"
      // const notManager = lvCode !== "08"
      const isHRDepartment = department === "ฝ่ายบริหารทรัพยากรบุคคล"

      const isHr = unitIsHR && isHRDepartment

      return isHr && tab === EnumApprovalStatus.WAITING
    }, [currentUser, tab])

    const getValue = useCallback((type: INPUT_TYPE, formName: string, values: any) => {
      const value = values[formName]
      return mapValue(value, type)
    }, [])

    const getStringValue = useCallback((type: INPUT_TYPE, formName: string, values: any) => {
      const value = values[formName]
      return mapStringValue(value, formName, type, values)
    }, [])

    const handleClosePage = useCallback(() => {
      setConfirm(showFooter, disableConfirm)
      paths.claimRequestsPath({ tab: tab, filters: filters }).push()
    }, [showFooter, disableConfirm, tab, filters])

    const handleCloseErrorModal = useCallback(
      (confirmProps: any) => {
        confirmProps.close()
        disableConfirm()
        setDisabledApproveButton(false)
        setDisabledRejectButton(false)
        // paths.claimRequestsPath({ tab: tab, filters: filters }).push()
      },
      [disableConfirm],
    )

    const onValidateError = useCallback((error: any) => {
      // @ts-ignore
      Modal.alert({
        className: "deleteConFirmModal",
        title: "",
        children: (
          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <Box justifyContent="center" padding={4}>
              <MuiIcon fontSize="large" color={AppColor["Warning"]} name="Warning"></MuiIcon>
            </Box>
            <Box justifyContent="flexStart" mb="16px">
              <List style={{ listStyleType: JSON.parse(error.message).length < 2 ? "none" : "inherit" }}>
                {JSON.parse(error.message).map((mes: any, index: number) => (
                  <li key={1150}>
                    <Typography variant="body1" color="Text/Black2">
                      {mes}
                    </Typography>
                  </li>
                ))}
              </List>
            </Box>
          </Box>
        ),
        okButtonLabel: "ตกลง",
        okButtonVariant: "contained",
        buttonWidth: "100%",
      })
    }, [])

    const openErrorModalAcademicYearMustUnique = (error: any) => {
      const isJsonError = JSON.parse(error.message)
      // @ts-ignore
      Modal.alert({
        title: "",
        children: (
          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <Box justifyContent="center" padding={2}>
              <MuiIcon fontSize="large" color={AppColor["Warning"]} name="Warning"></MuiIcon>
            </Box>
            <Box justifyContent="center" padding={4}>
              <Typography variant="h3" color={AppColor["Text/Primary Text"]}>
                เกิดข้อผิดพลาด
              </Typography>
            </Box>
            <Box
              style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}
              padding={1}
              mb="16px"
            >
              <Box>
                <Typography variant="Body/14" color={AppColor["Text/Primary Text"]}>
                  {isJsonError.message1}
                </Typography>
              </Box>
              <Box>
                <Typography variant="Body/14" color={AppColor["Other/Danger"]}>
                  "{isJsonError.message2}"
                </Typography>
              </Box>
              <Box>
                <Typography variant="Body/14" color={AppColor["Text/Primary Text"]}>
                  {isJsonError.message3}
                </Typography>
              </Box>
              <Box>
                <Typography variant="Body/14" color={AppColor["Text/Primary Text"]}>
                  {isJsonError.message4}
                </Typography>
              </Box>
              <Box>
                <Typography variant="Body/14" color={AppColor["Text/Primary Text"]}>
                  {isJsonError.message5}
                </Typography>
              </Box>
            </Box>
          </Box>
        ),
        okButtonLabel: "ตกลง",
        okButtonVariant: "contained",
        buttonWidth: "100%",
      })
    }

    const [documentValues, setDocumentValues] = useState({})

    const handleConfirmApprove = useCallback(
      async (date?: string, transferFast?: boolean, paymentType?: string) => {
        try {
          setDisabledApproveButton(true)
          setDisabledRejectButton(true)
          const info = {
            ...data?.claimRequest.info,
            conditions: currentActionWorkflow?.workflow.conditions,
            inputs: currentActionWorkflow?.workflow.inputs,
            values: { ...infoValues, documents: { ...documentValues } },
            rejectReason: { message: "" },
          }

          setPaymentType(paymentType, info)

          let params: {
            isApprove: boolean
            requestId: string
            createdBy: string
            seq: number
            transferDate?: string
            isTransferFast?: boolean
          } = {
            isApprove: true,
            seq: currentActionWorkflow?.seq,
            ...approveParams,
            approveValues: { ...infoValues, documents: { ...documentValues } },
            info,
            requestName: data?.claimRequest.type,
            employeeId: data?.claimRequest.employeeId,
            diseases,
          }

          setParams(params, date, transferFast)

          await approveRequest({ variables: { ...params } })
          // @ts-ignore
          Notification.success("บันทึกข้อมูลสำเร็จ")
          disableConfirm()
          setDisabledApproveButton(false)
          setDisabledRejectButton(false)
          paths.claimRequestsPath({ tab: tab, filters: filters }).push()
        } catch (e: any) {
          openErrorModal(e, handleCloseErrorModal)
        }
      },
      [
        data?.claimRequest.info,
        data?.claimRequest.type,
        data?.claimRequest.employeeId,
        currentActionWorkflow?.workflow.conditions,
        currentActionWorkflow?.workflow.inputs,
        currentActionWorkflow?.seq,
        infoValues,
        documentValues,
        approveParams,
        diseases,
        approveRequest,
        disableConfirm,
        tab,
        filters,
        handleCloseErrorModal,
      ],
    )

    const onValidateErrorAcademicYearMustUnique = useCallback((error: any) => {
      openErrorModalAcademicYearMustUnique(error)
    }, [])

    const handleValidateRequest = useCallback(
      async (info: any, requesterId: string, requestName: string, requestId: string) => {
        return checkValidateReq(
          validateRequest,
          onValidateError,
          onValidateErrorAcademicYearMustUnique,
          info,
          requesterId,
          requestName,
          requestId,
        )
      },
      [onValidateError, onValidateErrorAcademicYearMustUnique, validateRequest],
    )

    const handleOpenApproveModal = useCallback(async () => {
      setSubmitTransferFast(currentActionWorkflow?.workflow.submitTransferFast)

      if (currentActionWorkflow?.workflow.submitTransferDate) {
        const info = {
          ...data?.claimRequest.info,
          conditions: currentActionWorkflow?.workflow.conditions,
          inputs: currentActionWorkflow?.workflow.inputs,
          values: infoValues,
        }
        const isValid = await handleValidateRequest(
          info,
          data?.claimRequest.employeeId,
          data?.claimRequest.type,
          data?.claimRequest.id,
        )
        openApproveModal(isValid, setIsApproveModalOpen)
      } else {
        const title = data?.claimRequest.info.title
        const amount = typeof requestAmount === "string" ? Number(requestAmount.replaceAll(",", "")) : requestAmount

        const amountDisplay = getAmountDisplay(amount)
        let payDetail = title
        if (data?.claimRequest.type !== "child_support") payDetail = `${title} ${amountDisplay} บาท`

        // @ts-ignore
        Modal.open({
          children: (
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
              <Icon width="40px" height="40px" name="CheckCircle" color={AppColor.Success} />
              <Box mt="16px">
                <Typography variant="h3" color={AppColor["Text/Primary Text"]}>
                  ยอมรับการเบิกสวัสดิการ ?
                </Typography>
              </Box>
              <Box mb="4px">
                <Typography variant="body1" color={AppColor["Text/Placeholder"]}>
                  อนุมัติการเบิก
                </Typography>
              </Box>
              <Box mb="16px">
                <Typography variant="h6" color={AppColor["Primary/Primary Text"]} isCurrency>
                  {payDetail}
                </Typography>
              </Box>
            </div>
          ),
          onOk: () => {
            handleConfirmApprove()
            // @ts-ignore
            Modal.close()
          },
          okButtonLabel: "อนุมัติการเบิก",
          okButtonVariant: "contained",
          cancelButtonLabel: "ปิดหน้าต่างนี้",
          cancelButtonVariant: "outlined",
        })
      }
    }, [
      currentActionWorkflow?.workflow.submitTransferFast,
      currentActionWorkflow?.workflow.submitTransferDate,
      currentActionWorkflow?.workflow.conditions,
      currentActionWorkflow?.workflow.inputs,
      data?.claimRequest.info,
      data?.claimRequest.employeeId,
      data?.claimRequest.type,
      data?.claimRequest.id,
      infoValues,
      handleValidateRequest,
      requestAmount,
      handleConfirmApprove,
    ])

    const handleCloseApproveModal = useCallback(() => {
      setIsApproveModalOpen(false)
    }, [])

    const handleOpenRejectModal = useCallback(() => {
      setIsRejectModalOpen(true)
    }, [])
    const handleCloseRejectModal = useCallback(() => {
      setIsRejectModalOpen(false)
    }, [])

    useEffect(() => {
      setConfirmRefresh(showFooter, disableConfirmRefresh, enableConfirmRefresh)
    }, [enableConfirmRefresh, disableConfirmRefresh, showFooter])

    const isLoadedYearlyBudget = useMemo(() => {
      if (data?.claimRequest.info.type === "ค่ารักษาพยาบาล") return !isEmpty(yearlyBudget)
      return true
    }, [data?.claimRequest.info.type, yearlyBudget])

    const isLoaded = useMemo(() => isDetailLoaded && isLoadedYearlyBudget, [isDetailLoaded, isLoadedYearlyBudget])

    useEffect(() => {
      if (data?.claimRequest) {
        const fetchedValues = data?.claimRequest.info.values.inputs
        let initInputs = {}
        const inputs = getInputFromWorkflow(
          requestApproved,
          requestRejected,
          currentActionWorkflow,
          previousActionWorkflow,
        )
        inputs.forEach((input: any) => {
          initInputs = { ...initInputs, [input.name]: getValue(input.type, input.name, fetchedValues) }
        })

        setInitialValues({ ...initInputs })

        setDetailValues({ ...fetchedValues })

        setIsDetailLoaded(true)
      }
    }, [
      currentActionWorkflow?.workflow.inputs,
      data,
      getValue,
      requestApproved,
      requestRejected,
      currentUserIsHr,
      currentActionWorkflow,
      previousActionWorkflow,
      setInitialValues,
    ])

    const initialDocumentValues = useMemo(() => {
      let initialDocuments = {}
      data?.claimRequest.info.documents.forEach((doc: any) => {
        const docs = data?.claimRequest.info.values.documents[doc.name]
        initialDocuments = {
          ...initialDocuments,
          [doc.name]: getValueOrDefault(docs, []),
        }
      })

      return initialDocuments
    }, [data?.claimRequest.info.documents, data?.claimRequest.info.values.documents])

    useEffect(() => {
      setDocumentValues(initialDocumentValues)
    }, [initialDocumentValues])

    useEffect(() => {
      const initInputs = prepareInitInputs(
        requestApproved,
        requestRejected,
        currentActionWorkflow,
        previousActionWorkflow,
        getStringValue,
        values,
      )

      const initValues: InfoValuesConfig = {
        inputs: initInputs,
        documents: { ...data?.claimRequest.info.values.documents },
      }
      setInfoValues(initValues)
    }, [
      previousActionWorkflow?.workflow.inputs,
      data?.claimRequest.info.values.documents,
      getStringValue,
      currentActionWorkflow?.workflow.inputs,
      requestApproved,
      requestRejected,
      values,
      data?.claimRequest.info.inputs,
      currentUserIsHr,
      previousActionWorkflow,
      currentActionWorkflow,
    ])

    const handleFilesChange = useCallback((values: any) => {
      setDocumentValues(values)
    }, [])

    const handleConfirmReject = useCallback(
      async (rejectReason: any, remark?: string) => {
        try {
          setDisabledApproveButton(true)
          setDisabledRejectButton(true)
          await approveRequest({
            variables: {
              isApprove: false,
              seq: currentActionWorkflow?.seq,
              rejectReason: rejectReason,
              remark,
              ...approveParams,
              info: documentValues,
              requestName: data?.claimRequest.type,
              employeeId: data?.claimRequest.employeeId,
            },
          })
          // @ts-ignore
          Notification.success("บันทึกข้อมูลสำเร็จ")
          disableConfirm()
          setDisabledApproveButton(false)
          setDisabledRejectButton(false)
          paths.claimRequestsPath({ tab: tab, filters: filters }).push()
        } catch (e: any) {
          if (e) {
            // @ts-ignore
            Modal.alert({
              className: "deleteConFirmModal",
              title: "",
              children: (
                <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                  <Box mb="16px">
                    <MuiIcon fontSize="large" color={AppColor["Warning"]} name="Warning"></MuiIcon>
                  </Box>
                  <Box mb="16px">
                    <Typography variant="body1" color="Text/Black2">
                      {e.message}
                    </Typography>
                  </Box>
                </Box>
              ),
              okButtonLabel: "ตกลง",
              okButtonVariant: "contained",
              buttonWidth: "100%",
              onOk: handleCloseErrorModal,
            })
          }
        }
      },
      [
        approveRequest,
        currentActionWorkflow?.seq,
        approveParams,
        documentValues,
        data?.claimRequest.type,
        data?.claimRequest.employeeId,
        disableConfirm,
        tab,
        filters,
        handleCloseErrorModal,
      ],
    )

    const isApproveOrRejectTab = useMemo(
      () => [EnumApprovalStatus.APPROVED, EnumApprovalStatus.REJECTED].includes(tab),
      [tab],
    )

    useEffect(() => {
      setChildrenBirthDate(values, setFieldValue)
    }, [setFieldValue, values])

    const budgetYear = useMemo(() => {
      return setBudgetYear(data)
    }, [data])

    const customizeInputs = useMemo(() => {
      return mapCustomizeInput(
        requestApproved,
        requestRejected,
        isApproveOrRejectTab,
        currentActionWorkflow,
        isInstead,
        isPassAwayRequest,
        previousActionWorkflow,
      )
    }, [
      requestApproved,
      requestRejected,
      isApproveOrRejectTab,
      currentActionWorkflow,
      isInstead,
      isPassAwayRequest,
      previousActionWorkflow,
    ])

    const mappingPaymentTypeMessage = useMemo(() => {
      const paymentType = data?.claimRequest.info.values.inputs["payment"]
      return mapPaymentType(paymentType)
    }, [data?.claimRequest.info.values.inputs])

    const checkSummaryDetail = useMemo(() => {
      return isHaveSummaryDetail(currentActionWorkflow, nextActionWorkflow, requestApproved, requestRejected, tab)
    }, [currentActionWorkflow, nextActionWorkflow, requestApproved, requestRejected, tab])

    const setDiseaseList = useCallback(
      (disease: string) => {
        const diseaseList = diseases
        diseaseList.push(disease)
        setDiseases(diseaseList)
      },
      [diseases],
    )
    const showBudget = useMemo(() => {
      if (values && allBudgets) {
        return true
      } else {
        return false
      }
    }, [allBudgets, values])

    const hrWorkflowIndex = useMemo(
      // only bam
      () => data?.claimRequest?.workflowSeq?.findIndex((item: any) => item.workflow.custom === "approvers10kLv6"),
      [data?.claimRequest?.workflowSeq],
    )

    const hrWorkflow = useMemo(
      () => data?.claimRequest?.workflowSeq[hrWorkflowIndex],
      [data?.claimRequest?.workflowSeq, hrWorkflowIndex],
    )

    const nextHrWorkflow = useMemo(
      () => data?.claimRequest?.workflowSeq[hrWorkflowIndex + 1],
      [data?.claimRequest?.workflowSeq, hrWorkflowIndex],
    )

    const isHrApproved = useMemo(() => hrWorkflow?.status === EnumApprovalStatus.APPROVED, [hrWorkflow])
    const summary = useMemo(() => nextHrWorkflow?.workflow.summary, [nextHrWorkflow?.workflow.summary])

    useEffect(() => {
      const isBossWorkflow = isPassAwayRequest ? false : currentActionWorkflow?.seq === 0
      setPreview(isHrApproved, requestApproved, requestRejected, isApproveOrRejectTab, setIsPreview, isBossWorkflow)
    }, [
      currentActionWorkflow?.seq,
      isApproveOrRejectTab,
      isHrApproved,
      isPassAwayRequest,
      requestApproved,
      requestRejected,
    ])

    const [saveDraft] = useMutation(API.SAVE_DRAFT, {
      onCompleted: () => {
        // @ts-ignore
        Notification.success("บันทึกสำเร็จ")
      },
    })

    const handleClickSaveDraft = useCallback(async () => {
      const info = {
        ...data?.claimRequest.info,
        conditions: currentActionWorkflow?.workflow.conditions,
        inputs: currentActionWorkflow?.workflow.inputs,
        values: { ...infoValues, documents: { ...documentValues } },
        rejectReason: { message: "" },
      }

      const variables = {
        type: data?.claimRequest.type,
        config: data?.claimRequest.config,
        info,
        requestId: data?.claimRequest.id,
        employeeId: data?.claimRequest.employeeId,
        isHr: true,
        status: EnumClaimRequestStatus.WAITING,
      }

      await saveDraft({ variables })
    }, [
      currentActionWorkflow?.workflow.conditions,
      currentActionWorkflow?.workflow.inputs,
      data?.claimRequest.config,
      data?.claimRequest.employeeId,
      data?.claimRequest.id,
      data?.claimRequest.info,
      data?.claimRequest.type,
      diseases,
      documentValues,
      infoValues,
      saveDraft,
    ])

    const canSaveDraft = useMemo(() => {
      return (
        data?.claimRequest.status === EnumClaimRequestStatus.WAITING && currentUser.meta.enable_save_draft && !isPreview
      )
    }, [currentUser?.meta.enable_save_draft, data?.claimRequest.status, isPreview])

    const canApprove = useMemo(() => {
      // if (currentActionWorkflow?.seq !== 1) return true
      // return currentUser?.meta.enable_approve_button
      return true
    }, [currentActionWorkflow?.seq, currentUser?.meta.enable_approve_button])

    return {
      handleClosePage,

      handleOpenApproveModal,
      handleCloseApproveModal,
      isApproveModalOpen,
      handleConfirmApprove,
      isApprovePaymentDate,

      handleOpenRejectModal,
      handleCloseRejectModal,
      isRejectModalOpen,
      handleConfirmReject,

      claimId: data?.claimRequest.referenceId,
      createdBy: data?.claimRequest.employee.firstName + " " + data?.claimRequest.employee.lastName,
      createdAt: data?.claimRequest.createdAt,
      updatedAt: data?.claimRequest.updatedAt,
      inputs: customizeInputs,
      documents: getValueOrDefault(data?.claimRequest.info.documents, []),
      hasNoDocuments,
      inputValues: getValueOrDefault(infoValues.inputs, {}),
      documentValues,
      title: getValueOrDefault(data?.claimRequest.info.title, ""),
      subTitle: data?.claimRequest.info.subTitle,
      icon: data?.claimRequest.info.icon,
      iconColor: data?.claimRequest.info.iconColor,
      chipTitleColor: data?.claimRequest.info.chipTitleColor,
      chipBackgroundColor: data?.claimRequest.info.chipBackgroundColor,
      type: data?.claimRequest.info.type,
      name: data?.claimRequest.type,
      approvedAmount: data?.claimRequest.approvedAmount,
      approvedCount: data?.claimRequest.approvedCount,
      claimStatus: data?.claimRequest.status,
      currentUser,

      workflows: getValueOrDefault(data?.claimRequest.workflowSeq, []),
      submitTransferFast,
      showFooter,

      requesterValues: getRequester(employee),
      requester: employee,
      approvalSection,
      hasFile,
      requestAmount,
      claimRequest: data?.claimRequest,
      transferDate: getValueOrDefault(data?.claimRequest.transferDate, null),
      rejectReasons: getValueOrDefault(currentActionWorkflow?.workflow.rejectedReasons, null),
      rejectReason: getValueOrDefault(data?.claimRequest.rejectReason, null),
      rejectRemark: getValueOrDefault(data?.claimRequest.remark, null),
      rejectedBy: getValueOrDefault(data?.claimRequest.rejectedBy, null),
      transferFast: getValueOrDefault(data?.claimRequest.info.transferFast, false),
      currentUserIsHr,
      isInstead,
      isInsurance,
      error: approveError,
      defaultTransferDate,

      disabledApproveButton,
      disabledRejectButton,
      isLoaded,

      claimFor: data?.claimRequest.info.claimFor,
      affiliation: data?.claimRequest.employee.department,
      email: getValueOrDefault(data?.claimRequest.employee.officialMailId, "-"),
      employeeGroup: "-",
      phoneNumber: "-",

      master,
      isPreview,
      setFieldValue,
      values,
      detailValues,
      checkSummaryDetail,
      summary,
      isHrApproved,
      budgetValues: getValueOrDefault(allBudgets, {}),
      budgetDetail: getValueOrDefault(data?.claimRequest.info?.budget, {}),
      year: budgetYear,
      yearlyBudget: getValueOrDefault(yearlyBudget, {}),

      canEdit,

      isPassAwayRequest,

      mappingPaymentTypeMessage,

      validateClaimLoading,
      approveRequestLoading,

      tab,
      imageLoading,
      setImageLoading,

      handleFilesChange,

      filters,

      setDiseaseList,
      showBudget,

      handleClickSaveDraft,
      initialValues: props.initialValues,

      canSaveDraft: false,
      canApprove,
    }
  }),
)

const setConfirm = (showFooter: any, disableConfirm: any) => {
  if (!showFooter) {
    disableConfirm()
  }
}

const setConfirmRefresh = (showFooter: any, disableConfirmRefresh: any, enableConfirmRefresh: any) => {
  if (!showFooter) {
    disableConfirmRefresh()
  } else {
    enableConfirmRefresh()
  }
}

const setParams = (params: any, date: any, transferFast: any) => {
  if (date) params.transferDate = date
  if (transferFast !== undefined) params.isTransferFast = transferFast
}

const setPaymentType = (paymentType: any, info: any) => {
  if (paymentType !== undefined) info.values["inputs"]["payment"] = paymentType
}

const getAmountDisplay = (amount: any) => {
  return isNaN(amount) ? 0 : amount
}

const openApproveModal = (isValid: any, setIsApproveModalOpen: any) => {
  if (isValid) setIsApproveModalOpen(true)
}

const getInputFromWorkflow = (
  requestApproved: any,
  requestRejected: any,
  currentActionWorkflow: any,
  previousActionWorkflow: any,
) => {
  // return requestApproved || requestRejected
  //   ? previousActionWorkflow?.workflow.inputs
  //   :

  return currentActionWorkflow?.workflow.inputs
}

const isShowFooter = (requestApproved: boolean, requestRejected: boolean, tabIsNotWaiting: boolean) => {
  if (requestApproved || requestRejected || tabIsNotWaiting) return false
  return true
}

const mapApprovalSection = (requestRejected: any, requestApproved: any, data: any) => {
  if (requestRejected)
    return {
      label: "ปฏิเสธเมื่อ",
      approvalAt: dayjs(data?.claimRequest.approvalAt).format("DD/MM/YYYY"),
      detailLabel: "เหตุผล",
      detail: data?.claimRequest.rejectReason,
    }
  if (requestApproved)
    return {
      label: "อนุมัติเมื่อ",
      approvalAt: dayjs(data?.claimRequest.approvalAt).format("DD/MM/YYYY"),
      detailLabel: "โอนเข้า",
      detail: "บัญชีเงินเดือน",
      sub: `(${dayjs(data?.claimRequest.transferDate).format("DD/MM/YYYY")})`,
    }
  return undefined
}

const fetchYearlyBudget = (employee: any, data: any, refetchYearlyBudget: any) => {
  if (employee) {
    if (data?.claimRequest.info.type === "ค่ารักษาพยาบาล") {
      const variables = {
        filters: {
          code: employee.employeeCode,
          fullName: "",
          year: new Date(data?.claimRequest.createdAt).getFullYear(),
          isApp: true,
        },
      }
      refetchYearlyBudget({ variables: variables })
    }
  }
}

const getValueOrDefault = (value: any, defaultValue: any) => {
  return value || defaultValue
}

const getRequester = (employee: any) => {
  return employee ? employee.firstName + " " + employee.lastName : undefined
}

const getSummary = (
  requestApproved: any,
  requestRejected: any,
  currentActionWorkflow: any,
  nextActionWorkflow: any,
  tab: EnumClaimRequestStatus,
) => {
  return tab === EnumClaimRequestStatus.WAITING
    ? currentActionWorkflow?.workflow.summary
    : nextActionWorkflow?.workflow.summary
}

const isHaveSummaryDetail = (
  currentActionWorkflow: any,
  nextActionWorkflow: any,
  requestApproved: any,
  requestRejected: any,
  tab: EnumClaimRequestStatus,
) => {
  const workflowForCheck = tab === EnumClaimRequestStatus.WAITING ? currentActionWorkflow : nextActionWorkflow
  const hasSummaryDetails = workflowForCheck?.workflow?.summary?.details?.length > 0

  return requestApproved || requestRejected || hasSummaryDetails
}

const checkIsPassaway = (requestTitle: string) => {
  return (
    requestTitle === "ค่าช่วยเหลือจัดการงานศพ (3 เท่า)" ||
    requestTitle === "ค่าเจ้าภาพงานศพ" ||
    requestTitle === "ค่าอุปกรณ์เคารพศพ"
  )
}

const fetchBudgetData = (employee: any, values: any, refetchBudget: any) => {
  if (employee?.id) {
    if (values.children_name) {
      refetchBudget({
        variables: { employeeId: employee.id, childrenName: values.children_name["name"] },
      })
    } else {
      refetchBudget({
        variables: { employeeId: employee.id, childrenName: "" },
      })
    }
  }
}

const getAmountFieldNameForApprove = (status: any, history: any, amountFieldName: any) => {
  if (status) {
    return history?.info.values.inputs[amountFieldName]
  }
}

const getAmountFieldName = (func: any, history: any, amountFieldName: any) => {
  if (func !== undefined) {
    const result = func(history?.info, "update")
    return result
  }
  return amountFieldName
}

const checkInstead = (data: any) => {
  return data?.claimRequest.employee.id !== data?.claimRequest.createdBy.id
}

const getTranferDate = (data: any) => {
  if (data?.claimRequest.transferDate) return dayjs(data?.claimRequest.transferDate)
  return dayjs()
}

const checkInsurance = (data: any) => {
  return data?.claimRequest.info.type === "ประกันกลุ่ม" || data?.claimRequest.info.type === "ประกันสังคม"
}

// TODO: use shared function
const mapValue = (value: any, type: INPUT_TYPE) => {
  if (!value) return undefined
  switch (type) {
    case INPUT_TYPE.DATE:
      return stringToDate(value)
    case INPUT_TYPE.DATE_TIME:
      return stringToDate(value)
    case INPUT_TYPE.CURRENCY:
    case INPUT_TYPE.CURRENCY_NUMBER:
      return typeof value === "string" ? Number(value.replaceAll(",", "")) : value
    case INPUT_TYPE.SUM_AMOUNT:
      return typeof value === "string" ? Number(value.replaceAll(",", "")) : value
    default:
      return value
  }
}

const mapStringValue = (value: any, formName: string, type: INPUT_TYPE, values: any) => {
  if (value === undefined) {
    return undefined
  }
  switch (type) {
    case INPUT_TYPE.TIME_PICKER:
      return dayjs(value).format("HH:mm")
    case INPUT_TYPE.DATE:
      return dayjs(value).format("DD/MM/YYYY")
    case INPUT_TYPE.DATE_TIME:
      return dayjs(value).format("DD/MM/YYYY, HH:mm")
    case INPUT_TYPE.NUMBER:
    case INPUT_TYPE.CURRENCY:
    case INPUT_TYPE.SUM_AMOUNT:
      return toCurrency(value, { minimumFractionDigits: 0 })
    case INPUT_TYPE.CURRENCY_NUMBER:
      const newValue = typeof value === "string" ? Number(value.replaceAll(",", "")) : value
      return toCurrency(newValue, { minimumFractionDigits: 0 })
    case INPUT_TYPE.SELECT:
      return value === OptionValue.IS_OTHER ? values[formName + "_is_other"] : values[formName]
    case INPUT_TYPE.RELATION:
    case INPUT_TYPE.CHECKBOX:
      return value
    default:
      return value.toString()
  }
}

const checkValidateReq = async (
  validateRequest: any,
  onValidateError: any,
  onValidateErrorAcademicYearMustUnique: any,
  info: any,
  requesterId: any,
  requestName: any,
  requestId: any,
) => {
  try {
    await validateRequest({
      variables: { info, employeeId: requesterId, requestName, requestId, claimAction: EnumClaimAction.approve },
    })
  } catch (error: any) {
    const isJsonError = JSON.parse(error.message)
    if (isJsonError["code"] === 567) {
      onValidateErrorAcademicYearMustUnique(error)
    } else if (isJson(error.message)) {
      onValidateError(error)
    }
    return false
  }
  return true
}

const openErrorModal = (e: any, handleCloseErrorModal: any) => {
  if (e) {
    // @ts-ignore
    Modal.alert({
      className: "deleteConFirmModal",
      title: "",
      children: (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <Box mb="16px">
            <MuiIcon fontSize="large" color={AppColor["Warning"]} name="Warning"></MuiIcon>
          </Box>
          <Box mb="16px">
            <Typography variant="body1" color="Text/Black2">
              {e.message}
            </Typography>
          </Box>
        </Box>
      ),
      okButtonLabel: "ตกลง",
      okButtonVariant: "contained",
      buttonWidth: "100%",
      onOk: handleCloseErrorModal,
    })
  }
}

const prepareInitInputs = (
  requestApproved: any,
  requestRejected: any,
  currentActionWorkflow: any,
  previousActionWorkflow: any,
  getStringValue: any,
  values: any,
) => {
  let initInputs = {}
  // if (requestApproved || requestRejected) {
  //   previousActionWorkflow?.workflow.inputs.forEach((input: any) => {
  //     initInputs = { ...initInputs, [input.name]: getStringValue(input.type, input.name, values) }
  //   })
  // } else {
  currentActionWorkflow?.workflow.inputs.forEach((input: any) => {
    initInputs = { ...initInputs, [input.name]: getStringValue(input.type, input.name, values) }
  })
  // }

  return initInputs
}

const setPreview = (
  isHrApproved: boolean,
  requestApproved: any,
  requestRejected: any,
  isApproveOrRejectTab: boolean,
  setIsPreview: any,
  isBossWorkflow: boolean,
) => {
  if (isHrApproved || requestApproved || requestRejected || isApproveOrRejectTab || isBossWorkflow) setIsPreview(true)
  else setIsPreview(false)
}

const setChildrenBirthDate = (values: any, setFieldValue: any) => {
  if (values.children_name) {
    setFieldValue("children_birthdate", values.children_name.birthDate)
  }
}

const setBudgetYear = (data: any) => {
  if (data) return new Date(data?.claimRequest.createdAt).getFullYear() + 543 || "N/A"
}

const mapCustomizeInput = (
  requestApproved: any,
  requestRejected: any,
  isApproveOrRejectTab: any,
  currentActionWorkflow: any,
  isInstead: any,
  isPassAwayRequest: any,
  previousActionWorkflow: any,
) => {
  // if (requestApproved || requestRejected ) {
  //   const inputs = previousActionWorkflow?.workflow.inputs.map((input: any) => {
  //     return {
  //       ...input,
  //       options:
  //         isPassAwayRequest && !isInstead && input.options && Array.isArray(input.options)
  //           ? input.options.filter((input: any) => input !== "self")
  //           : input.options,
  //     }
  //   })
  //   return inputs
  // } else {
  const inputs = currentActionWorkflow?.workflow.inputs.map((input: any) => {
    return {
      ...input,
      options:
        isPassAwayRequest && !isInstead && input.options && Array.isArray(input.options)
          ? input.options.filter((input: any) => input !== "self")
          : input.options,
    }
  })
  return inputs
  // }
}

const mapPaymentType = (paymentType: any) => {
  switch (paymentType) {
    case "โอนเข้าบัญชีเงินเดือน":
      return "โอนเงิน"
    case "เงินสด":
      return "จ่ายเงินสด"
    case "เช็ค":
      return "จ่ายเช็ค"
    default:
      return "โอนเงิน"
  }
}

const ApprovalPage = enhancer(ApprovalPageComponent)

export default ApprovalPage
