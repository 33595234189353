import { theme } from "configs"
import { env } from "configs"
import { linearGradient } from "polished"

// const BamAppColor = {
//   "Gray/Flat Button Bg": "#CBD0D1",
//   "Text/Dark": "#B39E9A",
//   "Text/Blue": "#0085CA",
//   "Text/Primary Pink": "#E31C79",
//   "Text/Gray": "#CBD0D1",
//   "Text/Black2": "#2D2D2D",
//   "Text/Gray Preview": "#606365",
//   "Text/Gray Success": "#A2AAAD",
//   "Gray/Card Border, Line": "#A2AAAD",
//   "Text/Dark Grey": "#767676",
//   "Other/Danger": "#FB4444",
//   "Other/Warning": "#FF9800",
//   "Other Light/Danger": "#FCECE9",
//   "Primary/Brown": "#805D57",
//   "Dark Blue/Primary Text": "#003C71",
//   //
//   "Gray/Primary Text": "#2B2B2B",
//   "Light Blue/Flat Button Background": "#E3F1F9",
//   "Light /Button Background": "#E3F1F9",
//   "Error/Error Text": "#FFA500",
//   Success: "#00A66A",
//   Warning: "#FFA500",
//   "Error/Line": "#F6A660",
//   "Primary/Line": "#004C85",
//   "Primary/Hover": "#003D6B",
//   "Primary/Light Hover": "#B8DBF0",
//   "Primary/Primary Text": "#004C85",
//   "Gray/Bg": "#F5F6F6",
//   "White / White": "#FFFFFF",
//   "Primary/Background": "#004C85",
//   "Text/Primary Text": "#2D2D2D",
//   "Text/Secondary Text": "#333333",
//   "Text/Line": "#7C7C7C",
//   "Text/Placeholder": "#585858",
//   "Text/Background": "#EAEAEA",
//   "Approve / Waiting ": "#EEC11B",
//   "System/Warning Light": "#FFEDCC",
//   "System/Error Light Hover": "#FFEBEB",
// }

// const ScgAppColor = {
//   "Gray/Flat Button Bg": "#CBD0D1",
//   "Text/Dark": "#B39E9A",
//   "Text/Blue": "#0085CA",
//   "Text/Primary Pink": "#E31C79",
//   "Text/Gray": "#CBD0D1",
//   "Text/Black2": "#2B2B2B",
//   "Text/Gray Preview": "#606365",
//   "Text/Gray Success": "#A2AAAD",
//   "Gray/Card Border, Line": "#A2AAAD",
//   "Text/Dark Grey": "#767676",
//   "Other/Danger": "#F34336",
//   "Other/Warning": "#FF9800",
//   "Other Light/Danger": "#FCECE9",
//   "Primary/Brown": "#805D57",
//   "Dark Blue/Primary Text": "#003C71",
//   //
//   "Gray/Primary Text": "#2B2B2B",
//   "Light Blue/Flat Button Background": "#E3F1F9",
//   "Light /Button Background": "#FFE4E5",
//   "Error/Error Text": "#FE7015",
//   Success: "#74B62D",
//   Warning: "#EEC11B",
//   "Error/Line": "#F6A660",
//   "Primary/Line": "#C80000",
//   "Primary/Hover": "#C80000",
//   "Primary/Light Hover": "#C80000",
//   "Primary/Primary Text": "#EE2524",
//   "Gray/Bg": "#F5F6F6",
//   "White / White": "#FFFFFF",
//   "Primary/Background": "#EE2524",
//   "Text/Primary Text": "#000000",
//   "Text/Secondary Text": "#333333",
//   "Text/Line": "#7C7C7C",
//   "Text/Placeholder": "#585858",
//   "Text/Background": "#EAEAEA",
//   "Approve / Waiting ": "#FFFFFF",
//   "System/Warning Light": "#FFEDCC",
//   "System/Error Light Hover": "#FFEBEB",
// }

const colors = {
  big: theme.APP_COLOR,
  // bam: BamAppColor,
}
export const AppColor = colors[env.THEME as keyof typeof colors]

const colorGradient = {
  big:  [`${AppColor["Primary/Primary Text"]} 100%`, `${AppColor["Error/Line"]} 0%`],
  bpu1: [`${AppColor["Primary/Primary Text"]} 100%`, `${AppColor["Error/Line"]} 0%`],
  bpu: theme.BACKGROUND_COLOR_GRADIENT,
}
export const backGroundColorGradient = linearGradient({
  colorStops: colorGradient[env.THEME as keyof typeof colorGradient],
  toDirection: "213deg",
  fallback: AppColor["Primary/Primary Text"],
})
