import { ApolloError } from "@apollo/client"
import { removeToken, setToken } from "api"
import { Box, Button, Field, Form, TextField, Typography } from "components"
import { compose, withFormik, withHooks, withStores } from "enhancers"
import { PageContent } from "layouts"
import styled from "styled-components"
import { AppColor } from "theme/app-color"
import { gql, paths } from "utils/helper"
import { env } from "configs"
import * as yup from "yup"
import AuthModal from "./AuthModal"

const ResetButton = styled(Button)`
  margin-top: 200px;
  border-radius: 12px;
  &:focus {
    background-color: ${AppColor["Dark Blue/Primary Text"]};
  }
  &:active {
    background-color: ${AppColor["Dark Blue/Primary Text"]};
  }
  &:hover {
    opacity: 0.8;
    background-color: ${AppColor["Dark Blue/Primary Text"]};
  }
`

const ListContainer = styled(Box)`
  padding: 24px;
`

const List = styled.ul`
  margin-top: 16px;
  margin-left: -16px;
`

const SetupPasswordComponent = (props: any) => (
  <PageContent title="E-claim" titleCentered>
    <ListContainer>
      <Box>
        <Typography variant="h4" color="Text/Black2">
          ตั้งรหัสผ่านของผู้ใช้งาน
        </Typography>
      </Box>
      <Box mt={2}>
        <Typography variant="h2" color="Primary/Primary Text">
          {props.email}
        </Typography>
      </Box>

      <Form>
        <Box display="flex" mt={7}>
          <Typography variant="body1" color="Text/Black2">
            รหัสผ่าน
          </Typography>
        </Box>
        <Field component={TextField} name="password" type="password" placeholder="รหัสผ่าน" fullWidth mt={2} />
        <Box display="flex" mt={4}>
          <Typography variant="body1" color="Text/Black2">
            ยืนยันรหัสผ่าน
          </Typography>
        </Box>
        <Field
          component={TextField}
          name="confirmPassword"
          type="password"
          placeholder="ยืนยันรหัสผ่าน"
          fullWidth
          mt={2}
        />
        <List>
          <li>
            <Typography variant="h4" color="Text/Gray Preview">
              ต้องยาว 8-16 หลัก
            </Typography>
          </li>
          <li>
            <Typography variant="h4" color="Text/Gray Preview">
              ตัวอักษรภาษาอังกฤษอย่างน้อย 1 ตัว
            </Typography>
          </li>
          <li>
            <Typography variant="h4" color="Text/Gray Preview">
              ตัวเลขอย่างน้อย 1 ตัว
            </Typography>
          </li>
          <li>
            <Typography variant="h4" color="Text/Gray Preview">
              ไม่รองรับภาษาไทยและสัญลักษณ์พิเศษ
            </Typography>
          </li>
        </List>
        <ResetButton variant="contained" type="submit" fullWidth>
          ตั้งรหัสผ่าน
        </ResetButton>
      </Form>
      <Box display="flex" justifyContent="center" mt={4}>
        <Typography variant="caption" color="Text/Gray Success" style={{ fontSize: "12px" }}>
          App version 1.0
        </Typography>
      </Box>
    </ListContainer>
    <AuthModal
      isOpen={props.isModalOpen}
      title={props.message.title}
      description={props.message.description}
      isError={props.isError}
    />
  </PageContent>
)

export const API = {
  SETUP_PASSWORD: gql`
    mutation SETUP_PASSWORD($invitationToken: String!, $password: String!) {
      setupPassword(input: { invitationToken: $invitationToken, password: $password }) {
        id
        userToken
        employee {
          id
          employeeCode
          firstName
          lastName
          role
          grade
          officialMailId
          employmentType
          probationPeriod
          probationStatus
          functionalDesignation
          currentPoint
        }
      }
    }
  `,
  VALIDATE_USER: gql`
    mutation VALIDATE_USER($invitationToken: String!) {
      validateUser(input: { invitationToken: $invitationToken }) {
        id
        employee {
          id
          employeeCode
          firstName
          lastName
          role
          grade
          officialMailId
          employmentType
          probationPeriod
          probationStatus
          functionalDesignation
          currentPoint
        }
      }
    }
  `,
}

const validationSchema = yup.object().shape({
  password: yup
    .string()
    .required("รหัสผ่านต้องไม่ว่าง")
    .min(8, "รหัสผ่านต้องมีอย่างน้อย 8 ตัว")
    .max(16, "รหัสผ่านต้องมีไม่เกิน 16 ตัว")
    .matches(/[a-zA-Z]/, "ต้องมีตัวอักษรอย่างน้อย 1 ตัว")
    .matches(/\d/, "ต้องมีตัวเลขอย่างน้อย 1 ตัว")
    .matches(/^[a-zA-Z0-9]*$/, "ไม่รองรับภาษาไทยและสัญลักษณ์พิเศษ"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "รหัสผ่านไม่ตรงกัน")
    .required("กรุณายืนยันรหัสผ่าน"),
})

const enhancer = compose(
  withFormik({
    validationSchema,
    mapPropsToValues: () => ({
      password: "",
      confirmPassword: "",
    }),
  }),
  withHooks((props: any, hooks: any) => {
    const { useHandleSubmit, useMutation, useParams, useState, useEffect } = hooks
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isError, setIsError] = useState(false)
    const [message, setMessage] = useState({
      title: "ตั้งรหัสผ่านใหม่สำเร็จ",
      description: "กำลังเข้าสู่ระบบอัตโนมัติ",
    })
    const [email, setEmail] = useState("error")
    const { id } = useParams()

    const [validateUser] = useMutation(
      API.VALIDATE_USER,
      {
        fetchPolicy: "network-only",
        onCompleted: (data: any) => {
          const { validateUser } = data
          setEmail(validateUser.employee.officialMailId)
        },
        onError: (data: ApolloError) => {
          setIsModalOpen(true)
          setIsError(true)
          setMessage({
            title: "เกิดข้อผิดพลาด",
            description: data.message,
          })
          removeToken()
        },
      },
      [],
    )

    const [setupPassword] = useMutation(
      API.SETUP_PASSWORD,
      {
        fetchPolicy: "network-only",
        onCompleted: (data: any) => {
          setIsModalOpen(true)
          const { setupPassword } = data
          setToken(setupPassword.userToken)
          window.location.href = paths.landingPath()
        },
        onError: (data: ApolloError) => {
          setIsModalOpen(true)
          setIsError(true)
          setMessage({
            title: "เกิดข้อผิดพลาด",
            description: data.message,
          })
        },
      },
      [],
    )

    useEffect(() => {
      validateUser({
        variables: {
          invitationToken: id,
        },
      })
    }, [])

    useHandleSubmit(async (value: any) => {
      const { password } = value
      setupPassword({
        variables: {
          invitationToken: id,
          password: password,
        },
      })
    }, [])

    return { isModalOpen, email, isError, message }
  }),
)

export default enhancer(SetupPasswordComponent)
