export enum INPUT_TYPE {
  DATE = "DATE",
  TIME_PICKER = "TIME_PICKER",
  TEXT = "TEXT",
  TEXTAREA = "TEXTAREA",
  SELECT = "SELECT",
  NUMBER = "NUMBER",
  CURRENCY = "CURRENCY",
  RADIO = "RADIO",
  LABEL = "LABEL",
  RELATION = "RELATION",
  CHECKBOX = "CHECKBOX",
  DATE_TIME = "DATE_TIME",
  SUM_AMOUNT = "SUM_AMOUNT",
  CURRENCY_NUMBER = "CURRENCY_NUMBER",
  TEXT_BUTTON_WITH_CREATE_MODAL = "TEXT_BUTTON_WITH_CREATE_MODAL",
  TEXT_BUTTON_WITH_EDIT_MODAL = "TEXT_BUTTON_WITH_EDIT_MODAL",
}
