import { functions } from "configs"

const useAutoCal = (params) => {
  const { requestName, name: fieldName, auto: autoList } = params
  const funcs = {
    ...functions.autoCal,
  }
  const autoCal = {}
  autoList.forEach((propName) => {
    const funcKey = `${requestName}__${fieldName}__${propName}`
    const func = funcs[funcKey]
    if (!func) throw new Error(`${funcKey} function not found`)

    autoCal[propName] = func
  })
  return autoCal
}

export default useAutoCal
