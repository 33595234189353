import { Dialog } from "@material-ui/core"
import { ReactComponent as downloadIcon } from "assets/icon/download-icon.svg"
import { ReactComponent as Trash } from "assets/icon/red_trash.svg"
import {
  Box,
  Button,
  DatePicker,
  Field,
  Form,
  Grid,
  Modal,
  Notification,
  Select,
  Table,
  TextField,
  Typography,
} from "components"
import { ReportState } from "constants/enums/report-state"
import dayjs from "dayjs"
import { compose, withFormik, withHooks } from "enhancers"
import userStore from "stores/userStore"
import styled from "styled-components"
import { AppColor } from "theme/app-color"
import { forceDownload, gql, Yup } from "utils/helper"
const ResetFilterButton = styled(Box)`
  display: flex;
  align-items: center;
  text-decoration: underline;
  color: ${AppColor["Primary/Primary Text"]};
  :hover {
    cursor: pointer;
  }
`

const ClaimTable = styled(Table)<{}>(() => ({
  backgroundColor: AppColor["White / White"],
  "& .MuiDataGrid-columnHeader": {
    background: AppColor["Primary/Background"],
    borderRight: `1px solid ${AppColor["Primary/Primary Text"]}`,
  },
  "& .MuiDataGrid-columnsContainer": {
    border: `1px solid ${AppColor["Primary/Primary Text"]}`,
    borderRadius: "8px 8px 0px 0px",
    pointerEvents: "none",
  },
  "& .MuiDataGrid-dataContainer": {
    borderRight: `1px solid ${AppColor["Gray/Card Border, Line"]}`,
  },
  "& .MuiDataGrid-row": {
    borderTop: `1px solid ${AppColor["Primary/Primary Text"]}`,
    borderLeft: `1px solid ${AppColor["Gray/Card Border, Line"]}`,
    borderRight: `1px solid ${AppColor["Gray/Card Border, Line"]}`,
    borderBottom: `1px solid ${AppColor["Gray/Card Border, Line"]}`,
  },
  "& .MuiDataGrid-cell": {
    borderRight: `1px solid ${AppColor["Gray/Card Border, Line"]}`,
  },
  "& .MuiDataGrid-iconSeparator": {
    display: "none",
  },
  "& .MuiDataGrid-sortIcon": {
    display: "none",
  },
  "& .MuiTablePagination-root": {
    color: AppColor["Text/Primary Text"],
  },
  "& .MuiTablePagination-select.MuiSelect-selectMenu.MuiInputBase-input": {
    border: `1px solid ${AppColor["Text/Line"]}`,
  },
  "& .MuiTablePagination-actions": {
    marginLeft: "16px",
  },
  "& .MuiIconButton-root": {
    padding: "8px",
  },
  "& .MuiTypography-root": {
    fontWeight: 500,
  },
}))

const SummaryReportModal = (props: any) => (
  <Dialog open={props.isOpen} fullWidth maxWidth="md">
    <Form>
      <Box>
        <Box display="flex" justifyContent="space-between" mb={4}>
          <Typography variant="h3">{"สร้างรายงาน"}</Typography>
          {/* <ResetFilterButton onClick={props.clearFilter}>
        <Typography variant="subtitle1">{"รีเซ็ตตัวกรอง"}</Typography>
      </ResetFilterButton> */}
        </Box>
        <Grid container spacing={0}>
          <Grid item xs={12} mb={4}>
            <Field
              component={TextField}
              name="reportName"
              label="ชื่อรายงาน"
              placeholder="ชื่อรายงาน"
              fullWidth
              // value={props.values.reportName}
              required
            />
          </Grid>

          <Grid item xs={12} mb={4}>
            <Field
              component={Select}
              name="reportType"
              type="text"
              label="ประเภทรายงาน"
              placeholder="ประเภทรายงาน"
              options={props.reportTypeOptions}
              fullWidth
              required
            />
          </Grid>

          <Grid item xs={12} mb={4}>
            <Field
              component={Select}
              name="department"
              type="text"
              label="ฝ่าย/สังกัด"
              placeholder="สังกัด"
              options={props.departmentOptions}
              fullWidth
              required
              disableClearable={true}
            />
          </Grid>

          <Grid item xs={12} mb={4}>
            <Field
              component={Select}
              name="employee"
              type="text"
              label="พนักงานเจ้าของคำร้อง"
              placeholder="พนักงานเจ้าของคำร้อง"
              options={props.employeeGroupsOptions}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} mb={4}>
            <Field
              component={Select}
              name="claimRequestTo"
              type="text"
              label="เบิกให้"
              placeholder="เบิกให้"
              options={props.claimRequestOptions}
              fullWidth
            />
          </Grid>
          <Grid container spacing={6} mb={4}>
            <Grid item xs={12} sm={6}>
              <Field
                component={Select}
                name="relationWithOwner"
                type="text"
                label="ความสัมพันธ์กับเจ้าของคำร้อง"
                placeholder="ความสัมพันธ์กับเจ้าของคำร้อง"
                options={props.relationWithOwnerOptions}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                component={Select}
                name="payRound"
                type="text"
                label="รอบการจ่าย"
                placeholder="รอบการจ่าย"
                options={props.payRoundOptions}
                fullWidth
                required
              />
            </Grid>
          </Grid>

          <Grid container spacing={6} mb={4}>
            <Grid item xs={12} sm={6}>
              <Field
                fast={false}
                component={DatePicker}
                name="startDate"
                label="วันที่ทำรายการสำเร็จตั้งแต่"
                placeholder="วันที่ทำรายการสำเร็จตั้งแต่"
                fullWidth
                maxDate={props.values.endDate}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                fast={false}
                component={DatePicker}
                name="endDate"
                label="จนถึงวันที่"
                placeholder="จนถึงวันที่"
                fullWidth
                minDate={props.values.startDate}
                required
              />
            </Grid>
          </Grid>
        </Grid>
        <Box style={{ display: "flex", gap: "8px", justifyContent: "flex-end" }}>
          <Button variant="outlined" onClick={props.clearFilter}>
            ปิดหน้าต่างนี้
          </Button>
          <Button type="submit">ยืนยัน</Button>
        </Box>
      </Box>
    </Form>

    <Box width="100%" mb={-6} mt={4}>
      <Typography variant="h4" mb={4}>
        {props.title}
      </Typography>
      <ClaimTable
        className="reportListTable"
        columns={props.columns}
        rows={props.tableData}
        loading={props.loadingReport}
        density="compact"
        autoHeight
        autoDetectInitialFilterOnUrlQueryParams
        pageSize={props.pageSize}
        onPageSizeChange={props.onPageSizeChange}
        rowsPerPageOptions={[10, 25, 50]}
        style={{
          minHeight: 150,
        }}
      />
    </Box>
  </Dialog>
)
const API = {
  FETCH_REPORTS_BY_USER: gql`
    query FETCH_REPORTS_BY_USER($filters: JSON) {
      reportsByUser(input: { filters: $filters }) {
        id
        reportName
        reportType
        state
        startDate
        endDate
        createdAt
        excelFileUrl
      }
    }
  `,
  DELETE_REPORTS_BY_USER: gql`
    mutation DELETE_REPORTS_BY_USER($id: String) {
      deleteReportByUser(input: { id: $id })
    }
  `,
  GET_DEPARTMENT: gql`
    query GET_DEPARTMENT {
      getDepartmentOption {
        label
        value
      }
    }
  `,
  GET_REPORTOPTIONS: gql`
    query GET_REPORTOPTIONS {
      getSummaryReportTypeOption {
        label
        value
      }
    }
  `,
  GET_EMPLOYEEOWNSREQUEST: gql`
    query GET_EMPLOYEEOWNSREQUEST($department: String) {
      getEmployeeOwnsRequestOption(input: { department: $department }) {
        label
        value
      }
    }
  `,
  GET_CLAIMREQUESTTO: gql`
    query GET_CLAIMREQUESTTO($employee: String) {
      getClaimRequestTo(input: { employee: $employee }) {
        label
        value
      }
    }
  `,
  GET_PAYROUND: gql`
    query GET_PAYROUND {
      getPayRoundOption {
        label
        value
      }
    }
  `,
  GET_RELATIONWITHOWNER: gql`
    query GET_RELATIONWITHOWNER {
      getrelationWithOwner {
        label
        value
      }
    }
  `,
  GEN_REPORT_BY_USER: gql`
    mutation GEN_REPORT_BY_USER(
      $reportName: String
      $reportType: String
      $startDate: String
      $endDate: String
      $claimRequestTo: String
      $employee: String
      $department: String
      $payRound: String
      $relationWithOwner: String
      $userId: String
    ) {
      generateReportByUser(
        input: {
          reportName: $reportName
          reportType: $reportType
          startDate: $startDate
          endDate: $endDate
          claimRequestTo: $claimRequestTo
          employee: $employee
          department: $department
          payRound: $payRound
          relationWithOwner: $relationWithOwner
          userId: $userId
        }
      )
    }
  `,
}

const enhancer = compose(
  withFormik({
    mapPropsToValues: () => ({
      reportName: "Report_" + dayjs(new Date()).format("DDMMYYYYHHmm"),
      reportType: "",
      department: "all",
      role: undefined,
      payRound: "all",
      relationWithOwner: "all",
      claimRequestTo: "",
      startDate: undefined,
      endDate: undefined,
    }),
    validationSchema: Yup.object().shape({
      reportName: Yup.string().required(".reportNameWarning").nullable(),
      reportType: Yup.string().required(".reportTypeWarning").nullable(),
      department: Yup.string().required(".reportDepartmentWarning").nullable(),
      relationWithOwner: Yup.string().required(".relationWithOwnerWarning").nullable(),
      startDate: Yup.date().required(".startDateWarning").nullable(),
      endDate: Yup.date().required(".endDateWarning").nullable(),
    }),
  }),
  withHooks((props: any, hooks: any) => {
    const { values, setFieldValue, isOpen, handleCloseModal, resetForm } = props
    const { useQuery, useMemo, useEffect, useState, useCallback, useHandleSubmit, useMutation } = hooks
    const [pageSize, setPageSize] = useState(10)

    const { data: reportTypeOptions } = useQuery(API.GET_REPORTOPTIONS, {
      fetchPolicy: "network-only",
    })
    const { data: department, loading } = useQuery(API.GET_DEPARTMENT, {
      fetchPolicy: "network-only",
    })
    const { data: employeeOptions } = useQuery(API.GET_EMPLOYEEOWNSREQUEST, {
      fetchPolicy: "network-only",
      variables: { department: values.department },
    })
    const { data: claimRequestOptions } = useQuery(API.GET_CLAIMREQUESTTO, {
      fetchPolicy: "network-only",
      variables: { employee: values.employee },
    })
    const { data: payRoundOptions } = useQuery(API.GET_PAYROUND, {
      fetchPolicy: "network-only",
    })
    const { data: relationWithOwnerOptions } = useQuery(API.GET_RELATIONWITHOWNER, {
      fetchPolicy: "network-only",
    })
    const [genReport] = useMutation(API.GEN_REPORT_BY_USER, {
      onCompleted: () => {
        console.log("genReport onCompleted")
      },
    })
    const {
      loading: loadingReport,
      data: dataReport,
      error: errorReport,
      refetch,
    } = useQuery(API.FETCH_REPORTS_BY_USER, {
      fetchPolicy: "network-only",
    })

    const fetchData = useCallback(() => {
      refetch({
        filters: { userId: userStore.state.currentUser.id },
      })
    }, [refetch, values])

    const [deleteReport] = useMutation(API.DELETE_REPORTS_BY_USER, {
      onCompleted: () => {
        Notification.success("ลบรายงานสําเร็จ")
        fetchData()
      },
    })

    useEffect(() => {
      fetchData()
    }, [])

    useEffect(() => {
      if (values.department) {
        setFieldValue("employee", "")
        setFieldValue("claimRequestTo", "")
      }
    }, [setFieldValue, values.department])

    useEffect(() => {
      if (reportTypeOptions?.getSummaryReportTypeOption) {
        setFieldValue("reportType", reportTypeOptions?.getSummaryReportTypeOption[0].value)
      }
    }, [reportTypeOptions?.getSummaryReportTypeOption])

    useHandleSubmit(async (values: any) => {
      await genReport({
        variables: { ...values, userId: userStore.state.currentUser.id },
      })
      resetForm()
      fetchData()
    }, [])

    //poling
    useEffect(() => {
      let count = 1
      const intervalID = setInterval(() => {
        console.log(count++, "polling")
        // Check if all reports are complete
        const allReportsCompleteOrFail = dataReport?.reportsByUser.every(
          (items: any) => items.state === "complete" || items.state === "failed",
        )

        // If all reports are complete, clear the interval
        if (allReportsCompleteOrFail) {
          clearInterval(intervalID)
          return
        }

        // If not all reports are complete, continue refetching
        fetchData()
      }, 5000)

      // Cleanup function
      return () => {
        clearInterval(intervalID)
      }
    }, [dataReport?.reportsByUser, fetchData, values])

    const onPageSizeChange = useCallback((newPage: any) => setPageSize(newPage), [])

    const departmentOptions = useMemo(() => {
      return [{ label: "ทั้งหมด", value: "all" }].concat(department?.getDepartmentOption)
    }, [department, loading])

    const getReportStateDisplay = useCallback((state: string) => {
      switch (state) {
        case "complete":
          return "สำเร็จ"
        case "generating":
          return "กำลังสร้าง"
        case "failed":
          return "ล้มเหลว"
        default:
          return state
      }
    }, [])

    const columns = useMemo(
      () => [
        {
          width: 200,
          field: "reportName",
          headerName: "ชื่อรายงาน",
        },
        {
          width: 300,
          field: "reportType",
          headerName: "ประเภทรายงาน",
        },
        {
          width: 150,
          field: "startDate",
          headerName: "วันที่เริ่มต้น",
          type: "string",
          renderCell: (startDate: any) => {
            return dayjs(startDate.value).locale("th").add(543, "year").format("DD/MM/YYYY")
          },
        },
        {
          width: 150,
          field: "endDate",
          headerName: "วันที่สิ้นสุด",
          type: "string",
          renderCell: (endDate: any) => {
            return dayjs(endDate.value).locale("th").add(543, "year").format("DD/MM/YYYY")
          },
        },
        {
          width: 140,
          field: "state",
          headerName: "สถานะ",
          type: "string",
          renderCell: (state: any) => {
            return getReportStateDisplay(state.value)
          },
        },
        {
          width: 150,
          field: "createdAt",
          headerName: "วันที่สร้าง",
          type: "date",
          renderCell: (createdAt: any) => {
            return dayjs(createdAt.row.createdAt).locale("th").add(543, "year").format("DD/MM/YYYY, HH:mm")
          },
        },
        {
          width: 100,
          headerName: "Actoin",
          filterable: false,
          sortable: false,
          field: "actions",
          type: "actions",
        },
      ],
      [],
    )

    const downloadFile = useCallback(
      (excelFileUrl: string, reportName: string) => async () => {
        if (reportName && excelFileUrl) {
          const loadFile = async () => {
            try {
              const blob = await fetch(excelFileUrl).then((r) => r.blob())
              const file = new File([blob], reportName, {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              })
              if (file) {
                const url = URL.createObjectURL(file)
                forceDownload(url, reportName)
              }
            } catch (e) {
              Notification.error(e)
            }
          }

          loadFile()
        } else {
          Notification.error("ไม่สามารถ download file ได้")
        }
      },
      [],
    )

    const deleteFile = useCallback(
      (id: string, name: string) => () => {
        Modal.open({
          title: "Remove Report",
          children: (
            <Box flex={1} mb={5} display="flex" flexDirection="row" style={{ gap: "4px" }}>
              <Typography variant="body1">{"รายงาน"}</Typography>
              <Typography variant="body2">{name}</Typography>
              <Typography variant="body1">{" จะถูกลบและไม่สามารถกู้คืนได้"}</Typography>
            </Box>
          ),
          cancelButtonLabel: "Cancel",
          okButtonLabel: "Remove",
          okButtonVariant: "contained",
          cancelButtonVariant: "outlined",
          onOk: async ({ close }: any) => {
            await deleteReport({ variables: { id } })
            await fetchData()
            close()
          },
        })
      },
      [deleteReport, fetchData],
    )

    const tableData = useMemo(() => {
      if (loadingReport || errorReport) {
        return []
      }
      return dataReport?.reportsByUser.map((report: any) => {
        const { id, reportName, reportType, state, startDate, endDate, createdAt, excelFileUrl } = report
        return {
          id: id,
          reportName,
          reportType,
          state,
          startDate,
          endDate,
          createdAt,
          actions:
            state === ReportState.generating
              ? []
              : [
                  {
                    Icon: downloadIcon,
                    onClick: downloadFile(excelFileUrl, reportName),
                    disabled: !(state === ReportState.complete),
                  },
                  {
                    Icon: Trash,
                    onClick: deleteFile(id),
                    disabled: !(state === ReportState.complete || state === ReportState.failed),
                  },
                ],
        }
      })
    }, [loadingReport, errorReport, dataReport?.reportsByUser, downloadFile])

    const clearFilter = useCallback(async () => {
      resetForm()
      handleCloseModal()
    }, [])

    return {
      tableData,
      columns,
      loadingReport,

      pageSize,
      onPageSizeChange,

      reportTypeOptions: reportTypeOptions?.getSummaryReportTypeOption,
      values,
      clearFilter,
      employeeGroupsOptions: employeeOptions?.getEmployeeOwnsRequestOption,
      isOpen,
      departmentOptions,
      payRoundOptions: payRoundOptions?.getPayRoundOption,
      claimRequestOptions: claimRequestOptions?.getClaimRequestTo,
      relationWithOwnerOptions: relationWithOwnerOptions?.getrelationWithOwner,
    }
  }),
)

export default enhancer(SummaryReportModal)
