import { Box, Icon, Typography } from "components/common"
import { compose, withHooks } from "enhancers"
import CreateForm from "./createForm"

const TextButtonWithCreateModal = (props: any) => (
  <Box>
    <CreateForm
      isOpen={props.isOpen}
      onClose={() => props.setIsOpen(false)}
      maxDate={props.maxDate}
      claimType={props.claimType}
      requester={props.requester}
      refetch={props.refetch}
    />
    <Box
      display="flex"
      justifyContent="end"
      alignItems="center"
      onClick={props.openModal}
      style={{ cursor: "pointer" }}
    >
      <Box mr={2}>
        <Typography variant="Body/16" color="#005AE0">
          เพิ่มข้อมูลครอบครัว
        </Typography>
      </Box>
      <Box>
        <Icon name="arrowRight" />
      </Box>
    </Box>
  </Box>
)

const enhancer = compose(
  withHooks((props: any, hooks: any) => {
    const { requester, maxDate, claimType, refetchDependents } = props
    const { useCallback, useState } = hooks
    const [isOpen, setIsOpen] = useState(false)

    const openModal = useCallback(() => {
      setIsOpen(true)
    }, [])

    return {
      openModal,
      isOpen,

      requester,
      maxDate,
      claimType,
      setIsOpen,
      refetch: refetchDependents,
    }
  }),
)

export default enhancer(TextButtonWithCreateModal)
