import withHooks from "./withHooks"

const withConfirmRefresh = (outerProps) =>
  withHooks((props, hooks) => {
    const { useEffect, useRef, useCallback } = hooks

    const confirmInfo = useRef(
      {
        enabled: true,
      },
      [],
    )

    const enableConfirm = useCallback(() => {
      confirmInfo.current = {
        ...confirmInfo.current,
        enabled: true,
      }
    }, [])

    const disableConfirm = useCallback(() => {
      confirmInfo.current = {
        ...confirmInfo.current,
        enabled: false,
      }
    }, [])

    useEffect(() => {
      const handleBeforeUnload = (event) => {
        const { enabled } = confirmInfo.current
        if (enabled) {
          event.preventDefault()
          event.returnValue = ""
          return event.returnValue
        }
      }

      window.addEventListener("beforeunload", handleBeforeUnload)

      return () => {
        window.removeEventListener("beforeunload", handleBeforeUnload)
      }
    }, [])

    return { ...props, enableConfirmRefresh: enableConfirm, disableConfirmRefresh: disableConfirm }
  })

export default withConfirmRefresh
