import {
  DatePicker,
  DateTimePicker,
  RadioGroup,
  TextButtonWithCreateModal,
  TextButtonWithEditModal,
  TextField,
} from "components"
import { INPUT_TYPE } from "constants/enums/input-type"
import { KeyboardTimePicker } from "formik-material-ui-pickers"
import CustomField from "./CustomField"
import { Label } from "./Label"
import CustomSelect from "./CustomSelect"
import RelationSelect from "./RelationSelect"
import Textarea from "./Textarea"
import CustomCheckbox from "./CustomCheckbox"

export { CustomField as Field }

export const mappingComponent = (type: INPUT_TYPE) => {
  switch (type) {
    case INPUT_TYPE.TEXT:
      return TextField
    case INPUT_TYPE.TEXTAREA:
      return Textarea

    case INPUT_TYPE.DATE:
      return DatePicker

    case INPUT_TYPE.TIME_PICKER:
      return KeyboardTimePicker
    case INPUT_TYPE.SELECT:
      return CustomSelect
    case INPUT_TYPE.RELATION:
      return RelationSelect

    case INPUT_TYPE.NUMBER:
      return TextField.Number
    case INPUT_TYPE.CURRENCY:
      return TextField.Currency
    case INPUT_TYPE.CURRENCY_NUMBER:
      return TextField.CurrencyNumber

    case INPUT_TYPE.RADIO:
      return RadioGroup
    case INPUT_TYPE.CHECKBOX:
      return CustomCheckbox

    case INPUT_TYPE.LABEL:
      return Label

    case INPUT_TYPE.DATE_TIME:
      return DateTimePicker

    case INPUT_TYPE.TEXT_BUTTON_WITH_CREATE_MODAL:
      return TextButtonWithCreateModal

    case INPUT_TYPE.TEXT_BUTTON_WITH_EDIT_MODAL:
      return TextButtonWithEditModal

    default:
      return null
  }
}
