import compose from "enhancers/compose"
import { withFormik } from "formik"
import { withStateHandlers } from "recompose"

const withStateFormik = (config) =>
  compose(
    withStateHandlers(
      {},
      {
        setPropsToFormikBag: (formikBagProps) => (props) => ({
          ...formikBagProps,
          ...props,
        }),
        setInitialValues: (props) => (initialValues) => ({
          ...props,
          __initialValues__: initialValues,
        }),
      },
    ),
    withFormik({
      mapPropsToValues: (props) => {
        if (props.__initialValues__) {
          return props.__initialValues__
        }
        return {}
      },
      enableReinitialize: true,
      validateOnMount: true,
      validateOnChange: true,
      ...config,
      handleSubmit: async (values, formikBag) => {
        const { handleSubmit } = formikBag.props
        if (handleSubmit) {
          await handleSubmit(values)
        }
      },
    }),
  )

export default withStateFormik
