import { DialogActions, DialogContent, Dialog as MuiDialog } from "@material-ui/core"
import { DatePicker } from "@material-ui/pickers"
import { Box, Button, Checkbox, Field, Form, Icon, Select, Typography } from "components"
import dayjs from "dayjs"
import { compose, withFormik, withHooks } from "enhancers"
import { useCallback, useEffect, useState } from "react"
import styled from "styled-components"
import { AppColor } from "theme/app-color"

const Dialog = styled(MuiDialog)`
  .MuiDialog-paperScrollPaper {
    padding: 0;
    margin: 1vw;
    min-width: 343px;
    overflow: hidden;
  }
`

const ModalContainer = styled(DialogContent)`
  height: 100%;
  padding: 16px;
`

const ContentContainer = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
`

const TitleContainer = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0px auto;
  margin: 16px 0px 16px 0px;
`

const ActionContainer = styled(Box)`
  display: flex;
  width: 100%;
  padding: 0px 16px 16px 16px;
`

const ActionButton = styled(Button)`
  width: 100%;
`

interface ApproveModalProps {
  isOpen: boolean
  title: string
  handleClose: () => void
  handleConfirm: () => void
  amount: string
  isInsurance: string
  defaultTransferDate: dayjs.Dayjs
  submitTransferFast: any
  handleChangeValue: () => void
  dateValue: any
  handleChangeCheck: () => void
  master?: any
  paymentTypeOption: any[]
  handleChangeSelectValue: () => void
  isPassAwayRequest: boolean
  isPaymentDate: boolean
}

const ApproveModalComponent = (props: ApproveModalProps) => {
  return (
    <Dialog open={props.isOpen}>
      <ModalContainer>
        <ContentContainer>
          <Icon width="40px" height="40px" name="CheckCircle" color={AppColor.Success} />
          <TitleContainer>
            <Typography variant="h3" color={AppColor["Text/Primary Text"]}>
              ยอมรับการเบิกสวัสดิการ ?
            </Typography>
          </TitleContainer>
          <Box mb="4px">
            <Typography variant="body1" color={AppColor["Text/Placeholder"]}>
              อนุมัติการเบิก
            </Typography>
          </Box>
          <Box mb="16px">
            <Typography
              variant="h5"
              style={{ fontWeight: 500, lineHeight: "24px" }}
              color={AppColor["Primary/Primary Text"]}
            >
              {props.title + " " + props.amount + " "}
              คะแนน
            </Typography>
          </Box>
        </ContentContainer>
        {props.isPassAwayRequest && (
          <>
            <Box display="flex">
              <Typography variant="body1" color="Text/Black2">
                วิธีการจ่าย
              </Typography>
              <Typography variant="body1" color="Other/Danger">
                *
              </Typography>
            </Box>
            <Form>
              <Field
                component={Select}
                style={{ marginTop: 8 }}
                name="payment"
                type="text"
                placeholder="วิธีการจ่าย"
                fullWidth
                options={props.paymentTypeOption}
              />
            </Form>
            <br />
          </>
        )}
        {props.isPaymentDate && (
          <Form>
            <Box display="flex">
              <Typography variant="body1" color="Text/Black2">
                โอนเงินเข้าบัญชีเงินเดือนพนักงานวันที่
              </Typography>
              <Typography variant="body1" color="Other/Danger">
                *
              </Typography>
            </Box>
            <Box position="relative" pb="8px">
              <DatePicker
                style={{ marginTop: 8 }}
                label=""
                value={props.dateValue}
                format="dd/MM/yyyy"
                onChange={props.handleChangeValue}
              />
              <Box position="absolute" top={18} right={12}>
                <Icon width="24px" height="24px" name="DateRange" color={AppColor["Text/Gray Preview"]} />
              </Box>
            </Box>
            {props.isPassAwayRequest && (
              <Box display="flex">
                <Typography variant="body1" color="Text/Gray">
                  ตามใบรับเงินที่ผู้รับผลประโยชน์เซ็นต์รับ
                </Typography>
              </Box>
            )}
            {props.submitTransferFast && (
              <Box pt={3}>
                <Field
                  p={1}
                  component={Checkbox}
                  name="submitTransferFast"
                  className="submitTransferFast"
                  label="จ่ายเป็นกรณีเร่งด่วน"
                  onChange={props.handleChangeCheck}
                />
              </Box>
            )}
          </Form>
        )}
      </ModalContainer>
      <DialogActions>
        <ActionContainer>
          <ActionButton variant="outlined" onClick={props.handleClose} mr="16px">
            ปิดหน้าต่างนี้
          </ActionButton>
          <ActionButton variant="contained" onClick={props.handleConfirm}>
            ยืนยัน
          </ActionButton>
        </ActionContainer>
      </DialogActions>
    </Dialog>
  )
}

const enhancer = compose(
  withFormik({}),
  withHooks((props: any, hooks: any) => {
    const { useMemo } = hooks
    const {
      isOpen,
      onClose,
      title,
      onConfirm,
      amount,
      isInsurance,
      defaultTransferDate,
      submitTransferFast,
      master,
      setFieldValue,
      values,
      isPassAwayRequest,
      isPaymentDate = false
    } = props

    const [checked, setChecked] = useState(false)

    const paymentTypeOption = useMemo(() => {
      if (!master) {
        return []
      }

      const paymentOption = master["how_to_pay"]

      setFieldValue("payment", paymentOption[0].value)
      return paymentOption || []
    }, [master, setFieldValue])

    const defaultDateValue = useMemo(() => {
      const currentDate = new Date()
      const defaultDayOfMonth = 25
      const today = currentDate.getDate()
      const currentMonth = currentDate.getMonth()
      if (today <= defaultDayOfMonth) {
        return new Date(currentDate.getFullYear(), currentMonth, defaultDayOfMonth)
      } else {
        const nextMonth = currentMonth === 11 ? 0 : currentMonth + 1
        const nextYear = currentMonth === 11 ? currentDate.getFullYear() + 1 : currentDate.getFullYear()
        return new Date(nextYear, nextMonth, defaultDayOfMonth)
      }
    }, [])

    const [dateValue, setDateValue] = useState(defaultDateValue)
    const handleChangeValue = useCallback((value: any) => {
      if(value) setDateValue(dayjs(value).toDate())
    }, [])

    const handleChangeCheck = useCallback((event: any) => {
      setChecked(event.target.checked)
    }, [])

    const handleClose = useCallback(() => {
      setTimeout(() => {
        setFieldValue("payment", master["how_to_pay"][0].value)
        setDateValue(defaultDateValue)
      }, 500)
      onClose()
    }, [onClose, defaultDateValue, master, setFieldValue])

    const handleConfirm = useCallback(() => {
      const dateWithFormat = dayjs(dateValue).format("YYYY-MM-DD")
      onConfirm(dateWithFormat, submitTransferFast ? checked : undefined, values["payment"] || undefined)
      handleClose()
    }, [dateValue, onConfirm, submitTransferFast, checked, handleClose, values])

    useEffect(() => {
      setChecked(false)
    }, [])

    return {
      isOpen,
      handleClose,
      title,
      amount,
      isInsurance,
      defaultTransferDate,
      submitTransferFast,
      handleConfirm,
      handleChangeValue,
      dateValue,
      handleChangeCheck,

      master,
      paymentTypeOption,

      isPassAwayRequest,
      isPaymentDate,
    }
  }),
)

const ApproveModal = enhancer(ApproveModalComponent)

export default ApproveModal
