import { compose, withHooks } from "enhancers"
import { FormControlLabel, Typography } from "@material-ui/core"
import { Checkbox as FormikMuiCheckbox } from "formik-material-ui"

import { ReactComponent as CheckboxOutlineIcon } from "assets/icon/checkbox_outline.svg"
import { ReactComponent as CheckboxOutlineCheckedIcon } from "assets/icon/checkbox_outline_checked.svg"

import PropTypes from "prop-types"

const Checkbox = ({ className, label, ...checkboxProps }) => (
  <FormControlLabel
    className={className}
    control={
      <FormikMuiCheckbox
        icon={<CheckboxOutlineIcon />}
        checkedIcon={<CheckboxOutlineCheckedIcon />}
        color="primary"
        size="small"
        indeterminate={false}
        style={{ marginLeft: 5 }}
        {...checkboxProps}
      />
    }
    label={
      <Typography variant="body1" style={{ fontSize: 16 }} align="center">
        {label}
      </Typography>
    }
  />
)

const enhancer = compose(
  withHooks((props) => {
    return { ...props }
  }),
)

Checkbox.propTypes = {
  className: PropTypes.any.isRequired,
  label: PropTypes.any.isRequired,
}

export default enhancer(Checkbox)
