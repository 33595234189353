import { find, has } from "lodash";

export const isServer = typeof window === "undefined";
export const isClient = !isServer;

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function call(func) {
  func();
}

export const safe = (block, safeValue) => {
  try {
    return block();
  } catch (e) {
    return safeValue;
  }
};

export const safeAsync = async (block, safeValue) => {
  try {
    return await block();
  } catch (e) {
    return safeValue;
  }
};

export const AUTHENTICATION_TOKEN = "authenticationToken";

export const getToken = () => {
  return localStorage.getItem(AUTHENTICATION_TOKEN);
};

export const setToken = (token) => {
  localStorage.setItem(AUTHENTICATION_TOKEN, token);
};

export const clearToken = () => {
  localStorage.removeItem(AUTHENTICATION_TOKEN);
};

export const showCompanyMenu = (isShow) => {
  if (isClient) {
    document.body.classList.remove("aside-secondary-disabled");
    document.body.classList.add("aside-secondary-enabled");

    if (!isShow) {
      document.body.classList.add("aside-minimize");
    } else {
      document.body.classList.remove("aside-minimize");
    }
  }
};

export const cleanFilter = (obj, name, options) => {
  if (has(obj, name) && !find(options, { value: obj[name] })) {
    obj[name] = undefined;
  }
};
