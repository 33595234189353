import { compose, defaultProps, withHooks } from "enhancers"
import { IconButton, Box, Chip, Typography } from "components"
import { formatPhoneNumber, toCurrency, formatDate } from "utils/helper"
import { map, find, isEmpty } from "lodash"
import { format, parseISO } from "date-fns"
import { DataGrid, GridFooter, GridToolbar, GridOverlay } from "@material-ui/data-grid"

import { withStyles } from "@material-ui/core"

import { ReactComponent as FacebookIcon } from "assets/icon/facebook.svg"
import { ReactComponent as InstagramIcon } from "assets/icon/instagram.svg"
import { ReactComponent as LineIcon } from "assets/icon/line.svg"
import { ReactComponent as EmailIcon } from "assets/icon/email.svg"

import { ReactComponent as GreenDot } from "assets/icon/green_dot.svg"
import { ReactComponent as RedDot } from "assets/icon/red_dot.svg"

import { ReactComponent as WarningAmber } from "assets/icon/warning_amber.svg"
import { ReactComponent as WarningPink } from "assets/icon/warning_pink.svg"
import { AppColor } from "theme/app-color"

const StyledDataGrid = withStyles({
  root: {
    // "& .MuiDataGrid-viewport": {
    //   maxHeight: "none !important",
    // },
    // "& .MuiDataGrid-renderingZone": {
    //   maxHeight: "none !important",
    // },
    // "& .MuiDataGrid-cell": {
    //   lineHeight: "unset !important",
    //   maxHeight: "none !important",
    //   whiteSpace: "normal",
    // },
    // "& .MuiDataGrid-row": {
    //   maxHeight: "none !important",
    // },
  },
})(DataGrid)

export const RENDER_CELLS = {
  text(row) {
    const { value } = row
    if (!value) {
      return <></>
    }
    return (
      <Box fullWidth whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
        {value}
      </Box>
    )
  },
  caption(row) {
    const { value } = row
    if (!value) {
      return <></>
    }
    return (
      <Typography fullWidth whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis" variant="caption">
        {value}
      </Typography>
    )
  },
  phoneNumber(row) {
    const { value } = row
    if (!value) {
      return <></>
    }
    return formatPhoneNumber(value)
  },
  date(row) {
    const { value } = row
    if (!value) {
      return <></>
    }
    return value
  },
  dateOnly(row) {
    const { value } = row
    if (!value) {
      return <></>
    }

    const dateString = formatDate(value, "dd/MM/yyyy")

    return dateString
  },
  currency(row) {
    const { value } = row
    if (!value) {
      return <></>
    }
    return <Box marginLeft="auto">{toCurrency(value, { minimumFractionDigits: 0 })}</Box>
  },
  social(row) {
    const { value } = row
    if (!value) {
      return <></>
    }

    const [info, channel] = value.split("{{STUDATA_SPLIT_STRING}}")

    if (!info && !channel) {
      return <></>
    }

    const Icon = {
      facebook: FacebookIcon,
      ig: InstagramIcon,
      line: LineIcon,
      email: EmailIcon,
    }[channel]

    return (
      <Box display="flex" alignItems="center">
        {Icon && <Icon style={{ marginRight: 8 }} />}
        {info}
      </Box>
    )
  },
  selector(row) {
    const { value } = row
    if (!value) {
      return <></>
    }
    return find(row.colDef.options, { value })?.label
  },
  tags(row) {
    const { value } = row
    if (!value) {
      return <></>
    }
    const values = value.split(",")
    return (
      <Box whiteSpace="pre-wrap" p={1} pt={0}>
        {map(values, (v) => {
          return <Chip size="small" label={v} mr={1} mt={1} style={{ fontSize: 12, lineHeight: 14 }} />
        })}{" "}
      </Box>
    )
  },
  notiStatus(row) {
    const { value } = row
    if (!value && value !== false) {
      return <></>
    }

    return (
      <Box display="flex" alignItems="center" mx="auto">
        {value ? <GreenDot /> : <RedDot />}
      </Box>
    )
  },
  warningStatus(row) {
    const { value } = row
    if (!value && value !== false) {
      return <></>
    }

    return (
      <Box display="flex" alignItems="center" mx="auto">
        {value ? <WarningAmber /> : null}
      </Box>
    )
  },
  actions(row) {
    const { value } = row
    if (!value) {
      return <></>
    }

    return value.map((action) => {
      const { Icon, onClick, can } = action
      if (can === false) {
        return <></>
      }

      return (
        <IconButton
          key={`index-1`}
          onClick={(e) => {
            e.stopPropagation()
            onClick(row)
          }}
        >
          <Icon />
        </IconButton>
      )
    })
  },
}

const customValue = {
  date(row) {
    const { value } = row
    if (!value) {
      return null
    }
    return format(parseISO(value), "dd/MM/yyyy, HH:mm")
  },
  social(row) {
    const { value } = row

    if (!value.channel || !value.info) {
      return null
    }

    const { channel, info } = value

    return `${info}{{STUDATA_SPLIT_STRING}}${channel}`
  },
  tags(row) {
    const { value } = row
    if (!value) {
      return null
    }

    const labels = map(value, (v) => find(row.colDef.options, { value: v })?.label ?? "").join(",")

    return labels
  },
}

//https://github.com/mui-org/material-ui-x/blob/HEAD/packages/grid/_modules_/grid/constants/localeTextConstants.ts
//https://material-ui.com/components/data-grid/localization/
// components={{
//   Toolbar: GridToolbar,
// }}

const enhancer = compose(
  defaultProps({
    style: {
      minHeight: 606,
    },
    density: "compact",
    autoHeight: true,
    autoPageSize: true,
    disableSelectionOnClick: true,
    rowsPerPageOptions: [25, 50, 100],
  }),
  withHooks((props, hooks) => {
    const { columns, onRowClickTo, onRowClick, autoDetectInitialFilterOnUrlQueryParams, ...rest } = props
    const { useMemo, useCallback, useState, useUrlParam } = hooks
    const [pageSize, setPageSize] = useState(25)

    const customColumns = useMemo(() => {
      return map(columns, ({ type = "text", ...rest }) => {
        return {
          renderCell: RENDER_CELLS[type] || undefined,
          valueGetter: customValue[type] || undefined,
          ...rest,
        }
      })
    }, [columns])

    const customOnRowClick = useCallback(
      (row) => {
        if (onRowClickTo) {
          return onRowClickTo(row.id).push()
        }
        if (onRowClick) {
          return onRowClick(row)
        }
      },
      [onRowClickTo, onRowClick],
    )

    const onPageSizeChange = useCallback((newPage) => setPageSize(newPage), [])

    const urlParams = useUrlParam()
    const initialFilterModel = useMemo(() => {
      const items = map(urlParams, (value, key) => {
        return {
          columnField: key,
          operatorValue: "contains",
          value: value,
        }
      })

      return isEmpty(items) ? undefined : { items }
    }, [urlParams])

    const [filterModel, onFilterModelChange] = useState(initialFilterModel)

    const NoRowsOverlay = useCallback(() => {
      return (
        <GridOverlay
          style={{
            border: `1px solid ${AppColor["Gray/Card Border, Line"]}`,
            background: AppColor["Gray/Bg"],
          }}
        >
          <WarningPink style={{ marginRight: "6px" }} />
          <Typography variant="body1" color={AppColor["Gray/Card Border, Line"]}>
            ไม่พบข้อมูลที่ค้นหา กรุณาตรวจสอบค่าในตัวกรอง
          </Typography>
        </GridOverlay>
      )
    }, [])

    return {
      pageSize,
      onPageSizeChange,
      ...rest,
      columns: customColumns,
      onRowClick: customOnRowClick,
      filterModel,
      onFilterModelChange,
      components: {
        Toolbar: props.includeToolbar ? GridToolbar : undefined,
        NoRowsOverlay,
        Footer: () => <GridFooter />,
      },
    }
  }),
)

const CustomTable = enhancer(StyledDataGrid)

CustomTable.RENDER_CELLS = RENDER_CELLS

export default CustomTable
