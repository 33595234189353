import React from "react"
import { compose, withHooks } from "enhancers"
import { Avatar } from "components"
import PropTypes from "prop-types"

const ImageUpload = (props) => (
  <Avatar {...props} variant="square" style={{ height: 120, width: 120 }} src={props.src} />
)

const enhancer = compose(
  withHooks((props, hooks) => {
    const { useEffect } = hooks
    const { field, form, ...rest } = props

    const file = field.value
    const { url, filename } = file
    useEffect(() => {
      if (filename && url) {
        const loadFile = async () => {
          const blob = await fetch(url).then((r) => r.blob())
          const file = new File([blob], filename, { type: blob.type })
          form.setFieldValue(field.name, file)
        }
        loadFile()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [url, filename])

    const src = file instanceof File ? URL.createObjectURL(file) : null

    return {
      ...rest,
      src,
    }
  }),
)

ImageUpload.propTypes = {
  src: PropTypes.any.isRequired,
}

export default enhancer(ImageUpload)
